import React from 'react';
import {PFPContext} from './../../../../context';

export const MedHair = () => {
    const {hair, hairColor} = React.useContext(PFPContext);

    if (hair !== 'medHair') return null;

    return (
        <g id="med hair">
<path id="Rectangle 23" d="M1103.02 184H1281.59V339.228H1111.52C1106.82 339.228 1103.02 335.422 1103.02 330.728V184Z" fill={hairColor} stroke="black" stroke-width="3"/>
<path id="Rectangle 22" d="M1103.02 184H1281.59V339.228H1111.52C1106.82 339.228 1103.02 335.422 1103.02 330.728V184Z" fill="black" fill-opacity="0.25" stroke="black" stroke-width="3"/>
</g>
    );
}

export default MedHair;
