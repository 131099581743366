import React from 'react';
import {PFPContext} from './../../../context';

export const Hoodie = () => {
    const {clothes, clothesColor} = React.useContext(PFPContext);

    if (clothes !== 'hoodie') return null;

    return (
        <g id="sweatshirt">
<g id="lower sweatshirt">
<g id="Vector 7">
<g filter="url(#filter0_i_96_3150)">
<path d="M174.436 391.178C106.943 415.118 69.4725 471.099 63 502H436C417.495 453.199 396.55 425.469 323.79 387.966C304.584 379.382 294.539 380.692 284.324 375.117H214.676C200.812 379.069 197.618 382.955 174.436 391.178Z" fill={clothesColor}/>
</g>
<path d="M174.436 391.178C106.943 415.118 69.4725 471.099 63 502H436C417.495 453.199 396.55 425.469 323.79 387.966C304.584 379.382 294.539 380.692 284.324 375.117H214.676C200.812 379.069 197.618 382.955 174.436 391.178Z" stroke="black" stroke-width="3" stroke-linecap="round"/>
</g>
<path id="Line 1" d="M131.052 472.6C131.052 471.772 130.38 471.1 129.552 471.1C128.723 471.1 128.052 471.772 128.052 472.6L131.052 472.6ZM128.052 498.905L128.052 500.405L131.052 500.405L131.052 498.905L128.052 498.905ZM128.052 472.6L128.052 498.905L131.052 498.905L131.052 472.6L128.052 472.6Z" fill="black"/>
<path id="Line 2" d="M372.496 472.6C372.496 471.772 371.824 471.1 370.996 471.1C370.167 471.1 369.496 471.772 369.496 472.6L372.496 472.6ZM369.496 498.905L369.496 500.405L372.496 500.405L372.496 498.905L369.496 498.905ZM369.496 472.6L369.496 498.905L372.496 498.905L372.496 472.6L369.496 472.6Z" fill="black"/>
</g>
<g id="Group 4">
<path id="Vector 15" d="M364.805 401.422L348.554 416.895L334.625 405.29L351.649 392.911L364.805 401.422Z" fill={clothesColor}/>
<path id="Vector 13" d="M359.145 407.741C359.004 407.975 358.828 408.191 358.614 408.385C358.792 408.17 358.969 407.955 359.145 407.741C361.346 404.093 354.891 396.211 347.78 401.422C340.214 406.966 282.781 436.948 278.907 438.558L370.222 335.659C389.321 356.894 387.189 373.649 359.145 407.741Z" fill={clothesColor}/>
<path id="Vector 14" d="M359.145 407.741C359.004 407.975 358.828 408.191 358.614 408.385C358.792 408.17 358.969 407.955 359.145 407.741C361.346 404.094 354.891 396.211 347.78 401.422C340.214 406.966 282.781 436.948 278.907 438.558L370.222 336.433C389.322 357.668 387.189 373.659 359.145 407.741Z" fill="black" fill-opacity="0.3"/>
<path id="Vector 12" d="M333.077 426.953C356.035 415.606 396.533 379.295 377.961 343.396" stroke="black" stroke-width="3" stroke-linecap="round"/>
</g>
<g id="Group 5">
<path id="Vector 9" d="M372.543 310.128C352.423 295.498 288.363 276.123 213.902 310.128C151.22 338.754 161.269 389.997 220.093 437.011C228.805 443.974 240.987 449.39 240.987 449.39L260.334 447.069C293.782 432.378 353.971 382.08 378.734 351.907C390.035 338.137 384.925 319.131 372.543 310.128Z" fill={clothesColor}/>
<path id="Vector 10" d="M189.913 358.096C182.368 388.269 215.45 433.916 248.726 433.916C282.002 433.916 333.077 376.664 353.971 353.454C374.865 330.244 335.398 318.51 324.564 313.996C313.73 309.483 197.458 327.923 189.913 358.096Z" fill={clothesColor} stroke="black" stroke-width="3"/>
<g id="Intersect">
<mask id="path-10-inside-1_96_3150" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M299.525 380.144C305.611 381.555 312.761 383.213 322.159 387.251C298.814 410.298 270.099 433.916 248.726 433.916C224.323 433.916 200.024 409.368 191.777 384.375C194.125 383.349 196.166 382.411 198.066 381.539C203.488 379.049 207.751 377.091 214.676 375.117H284.324C289.108 377.728 293.856 378.829 299.525 380.144Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M299.525 380.144C305.611 381.555 312.761 383.213 322.159 387.251C298.814 410.298 270.099 433.916 248.726 433.916C224.323 433.916 200.024 409.368 191.777 384.375C194.125 383.349 196.166 382.411 198.066 381.539C203.488 379.049 207.751 377.091 214.676 375.117H284.324C289.108 377.728 293.856 378.829 299.525 380.144Z" fill="white"/>
<path d="M322.159 387.251L324.267 389.386L327.439 386.254L323.343 384.495L322.159 387.251ZM299.525 380.144L298.847 383.066L298.847 383.066L299.525 380.144ZM191.777 384.375L190.575 381.627L188.071 382.721L188.928 385.316L191.777 384.375ZM198.066 381.539L199.318 384.265L199.318 384.265L198.066 381.539ZM214.676 375.117V372.117H214.257L213.854 372.232L214.676 375.117ZM284.324 375.117L285.761 372.484L285.089 372.117H284.324V375.117ZM323.343 384.495C313.688 380.346 306.322 378.64 300.202 377.221L298.847 383.066C304.901 384.47 311.833 386.08 320.975 390.007L323.343 384.495ZM248.726 436.916C260.371 436.916 273.536 430.552 286.403 421.698C299.381 412.768 312.54 400.963 324.267 389.386L320.051 385.116C308.433 396.586 295.562 408.113 283.002 416.755C270.333 425.472 258.453 430.916 248.726 430.916V436.916ZM188.928 385.316C193.221 398.324 201.653 411.143 212.172 420.735C222.667 430.305 235.531 436.916 248.726 436.916V430.916C237.518 430.916 226.031 425.253 216.214 416.301C206.421 407.371 198.58 395.419 194.625 383.435L188.928 385.316ZM196.814 378.813C194.913 379.686 192.895 380.613 190.575 381.627L192.978 387.124C195.355 386.085 197.419 385.137 199.318 384.265L196.814 378.813ZM213.854 372.232C206.694 374.273 202.256 376.314 196.814 378.813L199.318 384.265C204.72 381.784 208.807 379.91 215.499 378.002L213.854 372.232ZM284.324 372.117H214.676V378.117H284.324V372.117ZM300.202 377.221C294.478 375.894 290.13 374.868 285.761 372.484L282.886 377.75C288.087 380.588 293.233 381.764 298.847 383.066L300.202 377.221Z" fill="black" mask="url(#path-10-inside-1_96_3150)"/>
</g>
<path id="Vector 16" d="M330.755 378.212C284.324 337.98 200.974 347.635 191.46 375.891C179.824 391.571 262.46 404.65 308.313 400.771L330.755 378.212Z" fill="black" fill-opacity="0.5"/>
<path id="Vector 17" d="M323.016 322.507C336.099 327.3 343.698 331.461 360.162 341.075C358.388 332.971 353.971 327.923 335.398 316.317L323.016 322.507Z" fill="black" fill-opacity="0.5"/>
<path id="Vector 11" d="M260.334 447.069C293.782 432.378 353.971 382.08 378.734 351.907C390.035 338.137 384.925 319.131 372.543 310.128C352.423 295.498 290.469 281.173 213.902 310.128C155.863 337.207 151.22 396.006 239.439 450.164" stroke="black" stroke-width="3" stroke-linecap="round"/>
</g>
</g>
    );
}

export default Hoodie;
