import React from 'react';
import {PFPContext} from './../../../context';

export const Hoodie = () => {
    const {clothes, clothesColor} = React.useContext(PFPContext);

    if (clothes !== 'hoodie') return null;

    return (
        <g id="sweatshirt">
<g id="lower sweatshirt">
<g id="Vector 7">
<g filter="url(#filter6_i_28_2171)">
<path d="M1151.52 395.848C1085.44 419.289 1048.76 474.106 1042.42 504.364H1407.58C1389.46 456.579 1368.96 429.426 1297.73 392.703C1278.92 384.298 1269.09 385.58 1259.09 380.121H1190.91C1177.34 383.991 1174.21 387.796 1151.52 395.848Z" fill={clothesColor}/>
</g>
<path d="M1151.52 395.848C1085.44 419.289 1048.76 474.106 1042.42 504.364H1407.58C1389.46 456.579 1368.96 429.426 1297.73 392.703C1278.92 384.298 1269.09 385.58 1259.09 380.121H1190.91C1177.34 383.991 1174.21 387.796 1151.52 395.848Z" stroke="black" stroke-width="3" stroke-linecap="round"/>
</g>
<path id="Line 1" d="M1109.08 475.576C1109.08 474.747 1108.4 474.076 1107.58 474.076C1106.75 474.076 1106.08 474.747 1106.08 475.576L1109.08 475.576ZM1106.08 501.333L1106.08 502.833L1109.08 502.833L1109.08 501.333L1106.08 501.333ZM1106.08 475.576L1106.08 501.333L1109.08 501.333L1109.08 475.576L1106.08 475.576Z" fill="black"/>
<path id="Line 2" d="M1345.44 475.576C1345.44 474.747 1344.77 474.076 1343.94 474.076C1343.11 474.076 1342.44 474.747 1342.44 475.576L1345.44 475.576ZM1342.44 501.333L1342.44 502.833L1345.44 502.833L1345.44 501.333L1342.44 501.333ZM1342.44 475.576L1342.44 501.333L1345.44 501.333L1345.44 475.576L1342.44 475.576Z" fill="black"/>
</g>
<g id="Group 4">
<path id="Vector 15" d="M1337.88 405.879L1321.97 421.03L1308.33 409.667L1325 397.545L1337.88 405.879Z" fill={clothesColor}/>
<path id="Vector 13" d="M1332.34 412.067C1332.2 412.295 1332.03 412.507 1331.82 412.697C1331.99 412.486 1332.17 412.276 1332.34 412.067C1334.49 408.495 1328.17 400.777 1321.21 405.879C1313.81 411.308 1257.58 440.665 1253.79 442.242L1343.18 341.485C1361.88 362.278 1359.79 378.684 1332.34 412.067Z" fill={clothesColor}/>
<path id="Vector 14" d="M1332.34 412.067C1332.2 412.295 1332.03 412.507 1331.82 412.697C1331.99 412.486 1332.17 412.276 1332.34 412.067C1334.49 408.495 1328.17 400.776 1321.21 405.879C1313.81 411.308 1257.58 440.665 1253.79 442.242L1343.18 342.242C1361.88 363.036 1359.79 378.693 1332.34 412.067Z" fill="black" fill-opacity="0.3"/>
<path id="Vector 12" d="M1306.82 430.879C1329.29 419.768 1368.94 384.212 1350.76 349.061" stroke="black" stroke-width="3" stroke-linecap="round"/>
</g>
<g id="Group 5">
<path id="Vector 9" d="M1345.45 316.485C1325.76 302.159 1263.05 283.188 1190.15 316.485C1128.79 344.515 1138.63 394.692 1196.21 440.727C1204.74 447.545 1216.67 452.848 1216.67 452.848L1235.61 450.576C1268.35 436.19 1327.27 386.939 1351.52 357.394C1362.58 343.911 1357.58 325.3 1345.45 316.485Z" fill={clothesColor}/>
<path id="Vector 10" d="M1166.67 363.455C1159.28 393 1191.67 437.697 1224.24 437.697C1256.82 437.697 1306.82 381.636 1327.27 358.909C1347.73 336.182 1309.09 324.692 1298.48 320.273C1287.88 315.854 1174.05 333.909 1166.67 363.455Z" fill={clothesColor} stroke="black" stroke-width="3"/>
<g id="Intersect">
<mask id="path-126-inside-1_28_2171" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1273.97 385.043C1279.93 386.425 1286.93 388.049 1296.13 392.003C1273.28 414.57 1245.17 437.697 1224.24 437.697C1200.35 437.697 1176.57 413.659 1168.49 389.187C1170.79 388.182 1172.79 387.264 1174.65 386.41C1179.96 383.972 1184.13 382.054 1190.91 380.121H1259.09C1263.77 382.678 1268.42 383.756 1273.97 385.043Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1273.97 385.043C1279.93 386.425 1286.93 388.049 1296.13 392.003C1273.28 414.57 1245.17 437.697 1224.24 437.697C1200.35 437.697 1176.57 413.659 1168.49 389.187C1170.79 388.182 1172.79 387.264 1174.65 386.41C1179.96 383.972 1184.13 382.054 1190.91 380.121H1259.09C1263.77 382.678 1268.42 383.756 1273.97 385.043Z" fill="white"/>
<path d="M1296.13 392.003L1298.24 394.137L1301.41 391.006L1297.31 389.246L1296.13 392.003ZM1273.97 385.043L1273.29 387.966L1273.29 387.966L1273.97 385.043ZM1168.49 389.187L1167.29 386.438L1164.79 387.533L1165.64 390.127L1168.49 389.187ZM1174.65 386.41L1173.4 383.684L1173.4 383.684L1174.65 386.41ZM1190.91 380.121V377.121H1190.49L1190.09 377.236L1190.91 380.121ZM1259.09 380.121L1260.53 377.488L1259.86 377.121H1259.09V380.121ZM1297.31 389.246C1287.86 385.182 1280.64 383.511 1274.65 382.121L1273.29 387.966C1279.22 389.34 1286 390.915 1294.95 394.759L1297.31 389.246ZM1224.24 440.697C1235.66 440.697 1248.56 434.455 1261.16 425.784C1273.87 417.037 1286.76 405.475 1298.24 394.137L1294.02 389.868C1282.65 401.098 1270.05 412.383 1257.76 420.841C1245.36 429.376 1233.74 434.697 1224.24 434.697V440.697ZM1165.64 390.127C1169.85 402.876 1178.11 415.436 1188.41 424.835C1198.7 434.213 1211.3 440.697 1224.24 440.697V434.697C1213.29 434.697 1202.06 429.162 1192.46 420.403C1182.88 411.665 1175.21 399.971 1171.34 388.247L1165.64 390.127ZM1173.4 383.684C1171.54 384.538 1169.56 385.446 1167.29 386.438L1169.69 391.936C1172.02 390.918 1174.04 389.989 1175.9 389.136L1173.4 383.684ZM1190.09 377.236C1183.07 379.236 1178.72 381.236 1173.4 383.684L1175.9 389.136C1181.19 386.707 1185.19 384.873 1191.73 383.006L1190.09 377.236ZM1259.09 377.121H1190.91V383.121H1259.09V377.121ZM1274.65 382.121C1269.05 380.821 1264.8 379.818 1260.53 377.488L1257.65 382.754C1262.75 385.538 1267.8 386.691 1273.29 387.966L1274.65 382.121Z" fill="black" mask="url(#path-126-inside-1_28_2171)"/>
</g>
<path id="Vector 16" d="M1304.55 383.152C1259.09 343.758 1177.49 353.212 1168.18 380.879C1156.79 396.233 1237.69 409.04 1282.58 405.241L1304.55 383.152Z" fill="black" fill-opacity="0.5"/>
<path id="Vector 17" d="M1296.97 328.606C1309.78 333.299 1317.22 337.374 1333.33 346.788C1331.6 338.852 1327.27 333.909 1309.09 322.545L1296.97 328.606Z" fill="black" fill-opacity="0.5"/>
<path id="Vector 11" d="M1235.61 450.576C1268.35 436.19 1327.27 386.939 1351.52 357.394C1362.58 343.911 1357.58 325.3 1345.45 316.485C1325.76 302.159 1265.11 288.132 1190.15 316.485C1133.33 343 1128.79 400.576 1215.15 453.606" stroke="black" stroke-width="3" stroke-linecap="round"/>
</g>
</g>
    );
}

export default Hoodie;
