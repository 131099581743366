import React from 'react';

export const ColorPicker = ({selected, choices, onChange}) => {
    return (
        <div className='choices'>
            {
                choices.map((choice) => (
                    <div className={`choice${choice.id === selected ? ' selected' : ''}`} key={choice.id} onClick={() => onChange(choice.id)}>{choice.label}</div>
                ))
            }
        </div>
    );
}

export default ColorPicker;
