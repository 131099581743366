import React from 'react';
import {PFPContext} from './../../../context';

export const HeadBand = () => {
    const {hair, headAccessory, headAccessoryColor, skinToneShadow} = React.useContext(PFPContext);

    if (headAccessory !== 'headBand') return null;

    if (hair === 'afro') {
        return (
            <g id="afro-sweat-band">
<path id="Intersect_18" fill-rule="evenodd" clip-rule="evenodd" d="M304 160H143V183H304V160Z" fill={skinToneShadow}/>
<g id="Group 24" filter="url(#filter19_i_96_3150)">
<g id="Rectangle 28_3">
<mask id="path-136-inside-33_96_3150" fill="white">
<path d="M134.195 146C134.195 142.686 136.881 140 140.195 140H354.162C357.475 140 360.162 142.686 360.162 146V172C360.162 175.314 357.475 178 354.162 178H140.195C136.881 178 134.195 175.314 134.195 172V146Z"/>
</mask>
<path d="M134.195 146C134.195 142.686 136.881 140 140.195 140H354.162C357.475 140 360.162 142.686 360.162 146V172C360.162 175.314 357.475 178 354.162 178H140.195C136.881 178 134.195 175.314 134.195 172V146Z" fill={headAccessoryColor} stroke="black" stroke-width="6" mask="url(#path-136-inside-33_96_3150)"/>
</g>
<rect id="Rectangle 29_3" x="135.743" y="155.599" width="221.558" height="5.51009" fill="black" fill-opacity="0.25"/>
<rect id="Rectangle 30_3" x="135.743" y="150.089" width="221.558" height="5.51009" fill="black" fill-opacity="0.5"/>
<rect id="Rectangle 31_3" x="135.743" y="161.11" width="221.558" height="5.51009" fill="black" fill-opacity="0.5"/>
</g>
</g>
        );
    }

    if (hair === 'medHair') {
        return (
            <g id="med-hair-sweat-band">
<path id="Intersect_17" fill-rule="evenodd" clip-rule="evenodd" d="M296 159.656H143.4V182.866H296V159.656Z" fill={skinToneShadow}/>
<g id="Group 26" filter="url(#filter18_i_96_3150)">
<g id="Rectangle 28_2">
<mask id="path-131-inside-32_96_3150" fill="white">
<path d="M120.564 145.528C120.81 142.407 123.414 140 126.545 140H361.455C364.586 140 367.19 142.407 367.436 145.528L369.489 171.528C369.764 175.017 367.008 178 363.508 178H124.492C120.993 178 118.236 175.017 118.511 171.528L120.564 145.528Z"/>
</mask>
<path d="M120.564 145.528C120.81 142.407 123.414 140 126.545 140H361.455C364.586 140 367.19 142.407 367.436 145.528L369.489 171.528C369.764 175.017 367.008 178 363.508 178H124.492C120.993 178 118.236 175.017 118.511 171.528L120.564 145.528Z" fill={headAccessoryColor} stroke="black" stroke-width="6" mask="url(#path-131-inside-32_96_3150)"/>
</g>
<rect id="Rectangle 29_2" x="121" y="156" width="246" height="5" fill="black" fill-opacity="0.25"/>
<rect id="Rectangle 30_2" x="121" y="150" width="246" height="6" fill="black" fill-opacity="0.5"/>
<rect id="Rectangle 31_2" x="121" y="161" width="246" height="6" fill="black" fill-opacity="0.5"/>
</g>
</g>
        );
    }


    return (
        <g id="sweat-band">
<path id="Intersect_16" fill-rule="evenodd" clip-rule="evenodd" d="M349.328 159.656H143.481V182.866H349.328V159.656Z" fill={skinToneShadow}/>
<g id="Group 25" filter="url(#filter17_i_96_3150)">
<g id="Rectangle 28">
<mask id="path-126-inside-31_96_3150" fill="white">
<path d="M134.195 146C134.195 142.686 136.881 140 140.195 140H354.162C357.475 140 360.162 142.686 360.162 146V172C360.162 175.314 357.475 178 354.162 178H140.195C136.881 178 134.195 175.314 134.195 172V146Z"/>
</mask>
<path d="M134.195 146C134.195 142.686 136.881 140 140.195 140H354.162C357.475 140 360.162 142.686 360.162 146V172C360.162 175.314 357.475 178 354.162 178H140.195C136.881 178 134.195 175.314 134.195 172V146Z" fill={headAccessoryColor} stroke="black" stroke-width="6" mask="url(#path-126-inside-31_96_3150)"/>
</g>
<rect id="Rectangle 29" x="135.743" y="155.599" width="221.558" height="5.51009" fill="black" fill-opacity="0.25"/>
<rect id="Rectangle 30" x="135.743" y="150.089" width="221.558" height="5.51009" fill="black" fill-opacity="0.5"/>
<rect id="Rectangle 31" x="135.743" y="161.11" width="221.558" height="5.51009" fill="black" fill-opacity="0.5"/>
</g>
</g>
    );
}

export default HeadBand;
