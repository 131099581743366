import React from 'react';
import {PFPContext} from '../../../context';

export const Afro = () => {
    const {hair, skinToneShadow, hairColor} = React.useContext(PFPContext);

    if (hair !== 'afro') return null;

    return (
        <g id="afro">
<path id="Intersect_15" fill-rule="evenodd" clip-rule="evenodd" d="M349.328 159.656H143.481V182.866H349.328V159.656Z" fill={skinToneShadow}/>
<g id="Group 23">
<g id="Group 14">
<g id="Union_6">
<mask id="path-122-inside-30_96_3150" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M166.386 27.7651C152.026 27.7651 140.386 39.4057 140.386 53.7651V177.64H304V231H326V192.743C325.905 188.005 326.437 186.571 335.145 186.571H354V175H353.971V53.7651C353.971 39.4057 342.33 27.7651 327.971 27.7651H166.386Z"/>
</mask>
<g filter="url(#filter16_i_96_3150)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M166.386 27.7651C152.026 27.7651 140.386 39.4057 140.386 53.7651V177.64H304V231H326V192.743C325.905 188.005 326.437 186.571 335.145 186.571H354V175H353.971V53.7651C353.971 39.4057 342.33 27.7651 327.971 27.7651H166.386Z" fill={hairColor}/>
</g>
<path d="M140.386 177.64H137.386V180.64H140.386V177.64ZM304 177.64H307V174.64H304V177.64ZM304 231H301V234H304V231ZM326 231V234H329V231H326ZM326 192.743H329V192.713L328.999 192.683L326 192.743ZM354 186.571V189.571H357V186.571H354ZM354 175H357V172H354V175ZM353.971 175H350.971V178H353.971V175ZM143.386 53.7651C143.386 41.0626 153.683 30.7651 166.386 30.7651V24.7651C150.369 24.7651 137.386 37.7489 137.386 53.7651H143.386ZM143.386 177.64V53.7651H137.386V177.64H143.386ZM304 174.64H140.386V180.64H304V174.64ZM307 231V177.64H301V231H307ZM326 228H304V234H326V228ZM323 192.743V231H329V192.743H323ZM335.145 183.571C332.916 183.571 331.059 183.661 329.544 183.909C328.053 184.153 326.597 184.595 325.418 185.521C324.134 186.53 323.513 187.842 323.232 189.147C322.976 190.331 322.977 191.633 323 192.803L328.999 192.683C328.975 191.483 329.007 190.829 329.097 190.412C329.134 190.237 329.168 190.184 329.163 190.193C329.16 190.197 329.152 190.209 329.141 190.223C329.129 190.236 329.122 190.242 329.125 190.239C329.156 190.215 329.459 190.003 330.514 189.83C331.545 189.661 333.019 189.571 335.145 189.571V183.571ZM354 183.571H335.145V189.571H354V183.571ZM351 175V186.571H357V175H351ZM353.971 178H354V172H353.971V178ZM350.971 53.7651V175H356.971V53.7651H350.971ZM327.971 30.7651C340.673 30.7651 350.971 41.0626 350.971 53.7651H356.971C356.971 37.7489 343.987 24.7651 327.971 24.7651V30.7651ZM166.386 30.7651H327.971V24.7651H166.386V30.7651Z" fill="black" mask="url(#path-122-inside-30_96_3150)"/>
</g>
</g>
<rect id="Rectangle 37" x="309" y="185" width="14" height="43" fill={hairColor}/>
</g>
</g>
    );
}

export default Afro;
