import React from 'react';
import {PFPContext} from './../../context';

export const EyeBrows = () => {
    const {eyeBrows} = React.useContext(PFPContext);

    if (eyeBrows === 'neutral') {
        return (
            <g id="neutral_2">
<path id="Vector 1_2" d="M158.185 196.171H210.807" stroke="black" stroke-width="12" stroke-linecap="round"/>
<path id="Vector 2_2" d="M227.058 196.171H279.681" stroke="black" stroke-width="12" stroke-linecap="round"/>
</g>
        );
    };

    return (
        <g id="right up brow">
<path id="Vector 1" d="M158.185 196.171H210.807" stroke="black" stroke-width="12" stroke-linecap="round"/>
<path id="Vector 2" d="M225.51 202.361C234.333 196.224 239.821 194.109 251.048 193.85C265.262 194.502 272.122 193.546 282.776 189.982" stroke="black" stroke-width="12" stroke-linecap="round"/>
</g>
    );
}

export default EyeBrows;
