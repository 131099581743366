import React from 'react';
import {PFPContext} from '../../context';

export const FacialHair = () => {
    const {facialHair, facialHairColor} = React.useContext(PFPContext);

    if (facialHair === 'none') {
        return null;
    };

    return (
        <g id="beard">
<mask id="path-164-inside-9_28_2171" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1327.27 263.455V275.576C1327.27 333.314 1280.47 380.121 1222.73 380.121C1164.99 380.121 1118.18 333.315 1118.18 275.576V252.848H1120.45V262.697C1120.45 271.03 1128.79 280.879 1134.85 280.879H1262.88C1274.24 280.879 1278.35 271.788 1278.35 262.697V224.316H1299.89V255.879C1299.89 260.5 1300 263.455 1309.09 263.455H1327.27Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1327.27 263.455V275.576C1327.27 333.314 1280.47 380.121 1222.73 380.121C1164.99 380.121 1118.18 333.315 1118.18 275.576V252.848H1120.45V262.697C1120.45 271.03 1128.79 280.879 1134.85 280.879H1262.88C1274.24 280.879 1278.35 271.788 1278.35 262.697V224.316H1299.89V255.879C1299.89 260.5 1300 263.455 1309.09 263.455H1327.27Z" fill={facialHairColor} />
<path d="M1327.27 263.455H1330.27V260.455H1327.27V263.455ZM1118.18 252.848V249.848H1115.18V252.848H1118.18ZM1120.45 252.848H1123.45V249.848H1120.45V252.848ZM1278.35 224.316V221.316H1275.35V224.316H1278.35ZM1299.89 224.316H1302.89V221.316H1299.89V224.316ZM1324.27 263.455V275.576H1330.27V263.455H1324.27ZM1324.27 275.576C1324.27 331.658 1278.81 377.121 1222.73 377.121V383.121C1282.12 383.121 1330.27 334.971 1330.27 275.576H1324.27ZM1222.73 377.121C1166.65 377.121 1121.18 331.658 1121.18 275.576H1115.18C1115.18 334.971 1163.33 383.121 1222.73 383.121V377.121ZM1121.18 275.576V252.848H1115.18V275.576H1121.18ZM1120.45 249.848H1118.18V255.848H1120.45V249.848ZM1123.45 262.697V252.848H1117.45V262.697H1123.45ZM1134.85 277.879C1134.11 277.879 1133.02 277.561 1131.67 276.689C1130.35 275.837 1128.97 274.575 1127.71 273.023C1125.14 269.857 1123.45 265.959 1123.45 262.697H1117.45C1117.45 267.768 1119.93 272.961 1123.05 276.802C1124.63 278.755 1126.47 280.476 1128.42 281.731C1130.33 282.966 1132.55 283.879 1134.85 283.879V277.879ZM1262.88 277.879H1134.85V283.879H1262.88V277.879ZM1275.35 262.697C1275.35 266.856 1274.4 270.714 1272.44 273.447C1270.58 276.038 1267.64 277.879 1262.88 277.879V283.879C1269.48 283.879 1274.28 281.174 1277.31 276.947C1280.25 272.862 1281.35 267.629 1281.35 262.697H1275.35ZM1275.35 224.316V262.697H1281.35V224.316H1275.35ZM1299.89 221.316H1278.35V227.316H1299.89V221.316ZM1302.89 255.879V224.316H1296.89V255.879H1302.89ZM1309.09 260.455C1304.59 260.455 1303.54 259.657 1303.33 259.413C1303.24 259.316 1303.11 259.111 1303.01 258.526C1302.9 257.883 1302.89 257.082 1302.89 255.879H1296.89C1296.89 256.987 1296.89 258.288 1297.09 259.499C1297.3 260.767 1297.76 262.167 1298.83 263.387C1300.95 265.775 1304.5 266.455 1309.09 266.455V260.455ZM1327.27 260.455H1309.09V266.455H1327.27V260.455Z" fill="black" mask="url(#path-164-inside-9_28_2171)"/>
</g>
    );
}

export default FacialHair;
