import React from 'react';
import {PFPContext} from './../../../context';

export const HeadBand = () => {
    const {hair, headAccessory, skinToneShadow, headAccessoryColor} = React.useContext(PFPContext);

    if (headAccessory !== 'headBand') return null;

    if (hair === 'afro') {
        return (
            <g id="afro-sweat-band">
<path id="Intersect_18" fill-rule="evenodd" clip-rule="evenodd" d="M1278.35 169.481H1120.74V192.003H1278.35V169.481Z" fill={skinToneShadow}/>
<g id="Group 24" filter="url(#filter31_i_28_2171)">
<path id="Rectangle 28_3" d="M1118.12 151.398H1327.33C1329.82 151.398 1331.83 153.412 1331.83 155.898V181.107C1331.83 183.592 1329.82 185.607 1327.33 185.607H1118.12C1115.64 185.607 1113.62 183.592 1113.62 181.107V155.898C1113.62 153.412 1115.64 151.398 1118.12 151.398Z" fill={headAccessoryColor} stroke="black" stroke-width="3"/>
<rect id="Rectangle 29_3" x="1113.64" y="165.172" width="216.896" height="5.3954" fill="black" fill-opacity="0.25"/>
<rect id="Rectangle 30_3" x="1113.64" y="159.777" width="216.896" height="5.3954" fill="black" fill-opacity="0.5"/>
<rect id="Rectangle 31_3" x="1113.64" y="170.568" width="216.896" height="5.39542" fill="black" fill-opacity="0.5"/>
</g>
</g>
        );
    }

    if (hair === 'medHair') {
        return (
            <g id="med-hair-sweat-band">
<path id="Intersect_17" fill-rule="evenodd" clip-rule="evenodd" d="M1270.52 169.144H1121.13V191.872H1270.52V169.144Z" fill={skinToneShadow}/>
<g id="Group 26" filter="url(#filter30_i_28_2171)">
<path id="Rectangle 28_2" d="M1104.75 151.398H1334.48C1336.83 151.398 1338.78 153.203 1338.97 155.544L1340.96 180.753C1341.16 183.369 1339.1 185.607 1336.47 185.607H1102.76C1100.13 185.607 1098.07 183.369 1098.27 180.753L1100.26 155.544C1100.45 153.203 1102.4 151.398 1104.75 151.398Z" fill={headAccessoryColor} stroke="black" stroke-width="3"/>
<rect id="Rectangle 29_2" x="1099.2" y="165.565" width="240.824" height="4.89595" fill="black" fill-opacity="0.25"/>
<rect id="Rectangle 30_2" x="1099.2" y="159.689" width="240.824" height="5.87512" fill="black" fill-opacity="0.5"/>
<rect id="Rectangle 31_2" x="1099.2" y="170.461" width="240.824" height="5.87512" fill="black" fill-opacity="0.5"/>
</g>
</g>
        );
    }


    return (
        <g id="sweat-band">
<path id="Intersect_16" fill-rule="evenodd" clip-rule="evenodd" d="M1322.73 169.144H1121.21V191.872H1322.73V169.144Z" fill={skinToneShadow}/>
<g id="Group 25" filter="url(#filter29_i_28_2171)">
<path id="Rectangle 28" d="M1118.12 151.398H1327.33C1329.82 151.398 1331.83 153.412 1331.83 155.898V181.107C1331.83 183.592 1329.82 185.607 1327.33 185.607H1118.12C1115.64 185.607 1113.62 183.592 1113.62 181.107V155.898C1113.62 153.412 1115.64 151.398 1118.12 151.398Z" fill={headAccessoryColor} stroke="black" stroke-width="3"/>
<rect id="Rectangle 29" x="1113.64" y="165.172" width="216.896" height="5.39542" fill="black" fill-opacity="0.25"/>
<rect id="Rectangle 30" x="1113.64" y="159.777" width="216.896" height="5.39542" fill="black" fill-opacity="0.5"/>
<rect id="Rectangle 31" x="1113.64" y="170.568" width="216.896" height="5.3954" fill="black" fill-opacity="0.5"/>
</g>
</g>
    );
}

export default HeadBand;
