import React from 'react';
import './App.css';
import Banner from './Banner';
import PFP from './PFP';
import ColorPicker from './ColorPicker';
import MultiSelect from './MultiSelect';
import {BannerContext, PFPContext} from './context';

const TONE_1 = '#F9C1A4';
const TONE_2 = '#C59485';
const TONE_3 = '#6D4D45';

const TONE_1_SHADOW = '#E98B61';
const TONE_2_SHADOW = '#AF7167';
const TONE_3_SHADOW = '#4C3026';

const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

function App() {
  const svgRef = React.useRef(null);
  const contentRef = React.useRef(null);
  const [type, setType] = React.useState('banner');

  const [textX, setTextX] = React.useState(0);
  const [bannerScale, setBannerScale] = React.useState(1);
  const [bannerText, setBannerText] = React.useState('abancx');
  const [bannerColor, setBannerColor] = React.useState('#E9F3D0');
  const [subjectType, setSubject] = React.useState('potential');

  const [skinTone, setSkinTone] = React.useState('tone1');
  const [clothes, setClothes] = React.useState('hoodie');
  const [clothesColor, setClothesColor] = React.useState('#2B2B2B');
  const [mouth, setMouth] = React.useState('open');
  const [facialHair, setFacialHair] = React.useState('beard');
  const [facialHairColor, setFacialHairColor] = React.useState('#2B2B2B');
  const [eyes, setEyes] = React.useState('neutral');
  const [eyeAccessory, setEyeAccessory] = React.useState('sunGlasses');
  const [hair, setHair] = React.useState('none');
  const [hairColor, setHairColor] = React.useState('#2B2B2B');
  const [eyeBrows, setEyeBrows] = React.useState('angled');
  const [headAccessory, setHeadAccessory] = React.useState('beenie');
  const [headAccessoryColor, setHeadAccessoryColor] = React.useState('#CCEB84');

  const handleTypeChange = (type) => {
    setType(type);
  }

  const handleDownload = () => {
    window.downloadSvg(svgRef.current, `pssssd-${type}.png`, type);

    if (isMobile) {
      document.querySelector('#mobile-image-container').classList.remove('hidden');
      setTimeout(() => {
        window.downloadSvg(svgRef.current, `pssssd-${type}.png`, type);
      }, 1000);
    }
  }

  const handleBannerNameChange = (e) => {
    setBannerText(e.target.value);
  }
  
  const bannerValue = {
    bannerScale, setBannerScale,
    textX, setTextX,
    bannerText,
    bannerColor,
    subjectType
  }

  let skinToneColor = TONE_1;
  let skinToneShadow = TONE_1_SHADOW;

  if (skinTone === 'tone2') {
    skinToneColor = TONE_2;
    skinToneShadow = TONE_2_SHADOW;
  } else if (skinTone === 'tone3') {
    skinToneColor = TONE_3;
    skinToneShadow = TONE_3_SHADOW;
  }

  const PFPValue = {
    skinTone: skinToneColor,
    skinToneShadow: skinToneShadow,
    clothes,
    clothesColor,
    mouth,
    facialHair,
    facialHairColor,
    eyeAccessory,
    eyeBrows,
    eyes,
    hair,
    hairColor,
    headAccessory,
    headAccessoryColor,
  }

  return (
    <BannerContext.Provider value={bannerValue}>
      <PFPContext.Provider value={PFPValue}>
    <div className="App">
      <div className='header'>
        <h1 className='title'>PSSSSD Fan Lab</h1>
        <div className='type-selection-container'>
          <div className={`type-choice${type === 'PFP' ? ' selected' : ''}`} onClick={() => handleTypeChange('PFP')}>PFP</div>
          <div className={`type-choice${type === 'banner' ? ' selected' : ''}`} onClick={() => handleTypeChange('banner')}>Banner</div>
        </div>
        
        <button onClick={handleDownload} className='download-button'><svg viewbox="0 0 92 20">
    <defs>
      <linearGradient id="gradient">
        <stop stop-color="#BFE657" />
      </linearGradient>
      <pattern id="wave" x="0" y="-0.5" width="100%" height="100%" patternUnits="userSpaceOnUse">
        <path id="wavePath" d="M-40 9 Q-30 7 -20 9 T0 9 T20 9 T40 9 T60 9 T80 9 T100 9 T120 9 V20 H-40z" mask="url(#mask)" fill="url(#gradient)"> 
          <animateTransform
              attributeName="transform"
              begin="0s"
              dur="1.5s"
              type="translate"
              from="0,0"
              to="40,0"
              repeatCount="indefinite" />
        </path>
      </pattern>
    </defs>
    <text text-anchor="middle" x="50" y="15" font-size="17" fill="#fff" fill-opacity=".5">DOWNLOAD</text>
    <text text-anchor="middle" x="50" y="15" font-size="17" fill="url(#wave)"  fill-opacity="1">DOWNLOAD</text>
  </svg></button>
      </div>

      <div className={`content ${type}`} ref={contentRef}>
        {(type === 'banner') && <Banner bannerRef={svgRef} bannerText={bannerText} bannerColor={bannerColor} />}
        {(type === 'PFP' && <PFP bannerRef={svgRef} bannerColor={bannerColor} />)}
      </div>

      <div className={`controls-container ${type}`}>
        <div className='controls'>
          <div className='banner-controls'>
              <div className='controls-title'>{type}</div>
              { (type === 'banner') && (
                <div className='controls-row'>
                  <div className='controls-label'>Text</div>
                  <div className='controls-value'>
                    <input className='controls-input' onChange={handleBannerNameChange} value={bannerText} />
                  </div>
                </div>
              )}
              
              <div className='controls-row'>
                <div className='controls-label'>Background color</div>
                <div className='controls-value'>
                  <ColorPicker color={bannerColor} setColor={setBannerColor} />
                </div>
              </div>

              { (type === 'banner') && (
                <div className='controls-row'>
                  <div className='controls-label'>Test subject type</div>
                  <div className='controls-value'>
                    <MultiSelect selected={subjectType} choices={[
                      {id: 'potential', label: 'Potential'},
                      {id: 'selected', label: 'Selected'}
                    ]} onChange={setSubject} />
                  </div>
                </div>
              )}
          </div>

          <div className='subject-controls'>
              <div className='controls-title'>Subject</div>
              
              <div className='controls-row'>
                <div className='controls-label'>Skin tone</div>
                <div className='controls-value'>
                  <MultiSelect selected={skinTone} choices={[
                    {id: 'tone1', label: 'Tone 1'},
                    {id: 'tone2', label: 'Tone 2'},
                    {id: 'tone3', label: 'Tone 3'}
                  ]} onChange={setSkinTone} />
                </div>
              </div>

              <div className='controls-row'>
                <div className='controls-label'>Clothes</div>
                <div className='controls-value'>
                  <MultiSelect selected={clothes} choices={[
                    {id: 'hoodie', label: 'Hoodie'},
                    {id: 'naked', label: 'Naked'},
                  ]} onChange={setClothes} />
                </div>
              </div>

              {
                (clothes !== 'naked') && (
                  <div className='controls-row'>
                    <div className='controls-label'>Clothes color</div>
                    <div className='controls-value'>
                      <ColorPicker color={clothesColor} setColor={setClothesColor} />
                    </div>
                  </div>
                )
              }

              <div className='controls-row'>
                <div className='controls-label'>Mouth</div>
                <div className='controls-value'>
                  <MultiSelect selected={mouth} choices={[
                    {id: 'open', label: 'Open'},
                    {id: 'smile', label: 'Smile'},
                  ]} onChange={setMouth} />
                </div>
              </div>
              
              <div className='controls-row'>
                <div className='controls-label'>Facial hair</div>
                <div className='controls-value'>
                  <MultiSelect selected={facialHair} choices={[
                    {id: 'beard', label: 'Beard'},
                    {id: 'none', label: 'None'},
                  ]} onChange={setFacialHair} />
                </div>
              </div>

              {
                (facialHair !== 'none') && (
                  <div className='controls-row'>
                    <div className='controls-label'>Facial hair color</div>
                    <div className='controls-value'>
                      <ColorPicker color={facialHairColor} setColor={setFacialHairColor} />
                    </div>
                  </div>
                )
              }

              <div className='controls-row'>
                <div className='controls-label'>Eyes</div>
                <div className='controls-value'>
                  <MultiSelect selected={eyes} choices={[
                    {id: 'up', label: 'Up'},
                    {id: 'neutral', label: 'Neutral'},
                    {id: 'down', label: 'Down'},
                  ]} onChange={setEyes} />
                </div>
              </div>
              
              <div className='controls-row'>
                <div className='controls-label'>Eye Brows</div>
                <div className='controls-value'>
                  <MultiSelect selected={eyeBrows} choices={[
                    {id: 'angled', label: 'Angled'},
                    {id: 'neutral', label: 'Neutral'},
                  ]} onChange={setEyeBrows} />
                </div>
              </div>

              <div className='controls-row'>
                <div className='controls-label'>Eye accessory</div>
                <div className='controls-value'>
                  <MultiSelect selected={eyeAccessory} choices={[
                    {id: 'sunGlasses', label: 'Sun glasses'},
                    {id: 'vr', label: 'VR'},
                    {id: 'none', label: 'None'},
                  ]} onChange={setEyeAccessory} />
                </div>
              </div>

              <div className='controls-row'>
                <div className='controls-label'>Hair</div>
                <div className='controls-value'>
                  <MultiSelect selected={hair} choices={[
                    {id: 'medHair', label: 'Medium Hair'},
                    {id: 'afro', label: 'Afro'},
                    {id: 'none', label: 'None'},
                  ]} onChange={setHair} />
                </div>
              </div>

              {
                (hair !== 'none') && (
                  <div className='controls-row'>
                    <div className='controls-label'>Hair color</div>
                    <div className='controls-value'>
                      <ColorPicker color={hairColor} setColor={setHairColor} />
                    </div>
                  </div>
                )
              }

              <div className='controls-row'>
                <div className='controls-label'>Head accessory</div>
                <div className='controls-value'>
                  <MultiSelect selected={headAccessory} choices={[
                    {id: 'beenie', label: 'Beenie'},
                    {id: 'headBand', label: 'Head band'},
                    {id: 'none', label: 'None'},
                  ]} onChange={setHeadAccessory} />
                </div>
              </div>

              {
                (headAccessory !== 'none') && (
                  <div className='controls-row'>
                    <div className='controls-label'>Head accesory color</div>
                    <div className='controls-value'>
                      <ColorPicker color={headAccessoryColor} setColor={setHeadAccessoryColor} />
                    </div>
                  </div>
                )
              }
          </div>

          <footer>
            <p className='footer-nav'>Other projects: <a href='/infection-lab'>Infection Lab</a></p>
            <p className='footer'>Made by <a href='https://twitter.com/abancx' target="_blank" rel="noreferrer">@abancx</a></p>
          </footer>
        </div>
      </div>
    </div>
    </PFPContext.Provider>
    </BannerContext.Provider>
  );
}

export default App;
