import React from 'react';
import {PFPContext} from './../../context';

export const Eyes = () => {
    const {eyes, skinToneShadow} = React.useContext(PFPContext);

    if (eyes === 'up') {
        return (
            <g id="up">
<g id="left eye_3">
<path id="Ellipse 6_5" d="M220.127 225.582C220.127 243.486 205.613 258 187.709 258C169.805 258 155.292 243.486 155.292 225.582C155.292 207.678 169.805 193.164 187.709 193.164C205.613 193.164 220.127 207.678 220.127 225.582Z" fill={skinToneShadow}/>
<g id="Ellipse 2_5">
<mask id="path-71-inside-18_96_3150" fill="white">
<path d="M215.836 222.34C215.836 240.244 201.322 254.758 183.418 254.758C165.514 254.758 151 240.244 151 222.34C151 204.436 165.514 189.922 183.418 189.922C201.322 189.922 215.836 204.436 215.836 222.34Z"/>
</mask>
<path d="M215.836 222.34C215.836 240.244 201.322 254.758 183.418 254.758C165.514 254.758 151 240.244 151 222.34C151 204.436 165.514 189.922 183.418 189.922C201.322 189.922 215.836 204.436 215.836 222.34Z" fill="white" stroke="black" stroke-width="6" mask="url(#path-71-inside-18_96_3150)"/>
</g>
<path id="Subtract_5" fill-rule="evenodd" clip-rule="evenodd" d="M176.178 248.182C193.903 248.182 208.272 234.041 208.272 216.597C208.272 209.919 206.165 203.724 202.571 198.622C209.175 204.415 213.366 213.057 213.366 222.711C213.366 240.154 199.681 254.295 182.801 254.295C173.865 254.295 165.825 250.333 160.236 244.016C164.933 246.666 170.375 248.182 176.178 248.182Z" fill="#E1E0E0"/>
<g id="Ellipse 7_7">
<mask id="path-73-inside-19_96_3150" fill="white">
<path d="M215.836 222.34C215.836 240.244 201.322 254.758 183.418 254.758C165.514 254.758 151 240.244 151 222.34C151 204.436 165.514 189.922 183.418 189.922C201.322 189.922 215.836 204.436 215.836 222.34Z"/>
</mask>
<path d="M215.836 222.34C215.836 240.244 201.322 254.758 183.418 254.758C165.514 254.758 151 240.244 151 222.34C151 204.436 165.514 189.922 183.418 189.922C201.322 189.922 215.836 204.436 215.836 222.34Z" stroke="black" stroke-width="6" mask="url(#path-73-inside-19_96_3150)"/>
</g>
<ellipse id="Ellipse 4_5" cx="175.699" cy="216.165" rx="6.17484" ry="6.17484" fill="black"/>
</g>
<g id="right eye_3">
<path id="Ellipse 6_6" d="M289.625 225.582C289.625 243.486 275.111 258 257.207 258C239.304 258 224.79 243.486 224.79 225.582C224.79 207.678 239.304 193.164 257.207 193.164C275.111 193.164 289.625 207.678 289.625 225.582Z" fill={skinToneShadow}/>
<g id="Ellipse 2_6">
<mask id="path-76-inside-20_96_3150" fill="white">
<path d="M285.334 222.34C285.334 240.244 270.82 254.758 252.916 254.758C235.012 254.758 220.498 240.244 220.498 222.34C220.498 204.436 235.012 189.922 252.916 189.922C270.82 189.922 285.334 204.436 285.334 222.34Z"/>
</mask>
<path d="M285.334 222.34C285.334 240.244 270.82 254.758 252.916 254.758C235.012 254.758 220.498 240.244 220.498 222.34C220.498 204.436 235.012 189.922 252.916 189.922C270.82 189.922 285.334 204.436 285.334 222.34Z" fill="white" stroke="black" stroke-width="6" mask="url(#path-76-inside-20_96_3150)"/>
</g>
<path id="Subtract_6" fill-rule="evenodd" clip-rule="evenodd" d="M245.676 248.182C263.401 248.182 277.77 234.041 277.77 216.598C277.77 209.919 275.663 203.724 272.068 198.622C278.673 204.415 282.864 213.057 282.864 222.711C282.864 240.154 269.179 254.295 252.299 254.295C243.363 254.295 235.323 250.333 229.734 244.016C234.431 246.667 239.874 248.182 245.676 248.182Z" fill="#E1E0E0"/>
<g id="Ellipse 7_8">
<mask id="path-78-inside-21_96_3150" fill="white">
<path d="M285.334 222.34C285.334 240.244 270.82 254.758 252.916 254.758C235.012 254.758 220.498 240.244 220.498 222.34C220.498 204.436 235.012 189.922 252.916 189.922C270.82 189.922 285.334 204.436 285.334 222.34Z"/>
</mask>
<path d="M285.334 222.34C285.334 240.244 270.82 254.758 252.916 254.758C235.012 254.758 220.498 240.244 220.498 222.34C220.498 204.436 235.012 189.922 252.916 189.922C270.82 189.922 285.334 204.436 285.334 222.34Z" stroke="black" stroke-width="6" mask="url(#path-78-inside-21_96_3150)"/>
</g>
<ellipse id="Ellipse 4_6" cx="245.197" cy="216.165" rx="6.17484" ry="6.17484" fill="black"/>
</g>
</g>
        );
    };

    if (eyes === 'down') {
        return (
            <g id="down">
<g id="left eye">
<path id="Ellipse 6" d="M220.127 225.582C220.127 243.486 205.613 258 187.709 258C169.805 258 155.292 243.486 155.292 225.582C155.292 207.678 169.805 193.164 187.709 193.164C205.613 193.164 220.127 207.678 220.127 225.582Z" fill={skinToneShadow}/>
<g id="Ellipse 2">
<mask id="path-51-inside-10_96_3150" fill="white">
<path d="M215.836 222.34C215.836 240.244 201.322 254.758 183.418 254.758C165.514 254.758 151 240.244 151 222.34C151 204.436 165.514 189.922 183.418 189.922C201.322 189.922 215.836 204.436 215.836 222.34Z"/>
</mask>
<path d="M215.836 222.34C215.836 240.244 201.322 254.758 183.418 254.758C165.514 254.758 151 240.244 151 222.34C151 204.436 165.514 189.922 183.418 189.922C201.322 189.922 215.836 204.436 215.836 222.34Z" fill="white" stroke="black" stroke-width="6" mask="url(#path-51-inside-10_96_3150)"/>
</g>
<path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M176.178 248.182C193.903 248.182 208.272 234.041 208.272 216.597C208.272 209.919 206.165 203.724 202.571 198.622C209.175 204.415 213.366 213.057 213.366 222.711C213.366 240.154 199.681 254.295 182.801 254.295C173.865 254.295 165.825 250.333 160.236 244.016C164.933 246.666 170.375 248.182 176.178 248.182Z" fill="#E1E0E0"/>
<g id="Ellipse 7_3">
<mask id="path-53-inside-11_96_3150" fill="white">
<path d="M215.836 222.34C215.836 240.244 201.322 254.758 183.418 254.758C165.514 254.758 151 240.244 151 222.34C151 204.436 165.514 189.922 183.418 189.922C201.322 189.922 215.836 204.436 215.836 222.34Z"/>
</mask>
<path d="M215.836 222.34C215.836 240.244 201.322 254.758 183.418 254.758C165.514 254.758 151 240.244 151 222.34C151 204.436 165.514 189.922 183.418 189.922C201.322 189.922 215.836 204.436 215.836 222.34Z" stroke="black" stroke-width="6" mask="url(#path-53-inside-11_96_3150)"/>
</g>
<ellipse id="Ellipse 4" cx="175.699" cy="226.353" rx="6.17484" ry="6.17484" fill="black"/>
</g>
<g id="right eye">
<path id="Ellipse 6_2" d="M289.625 225.582C289.625 243.486 275.111 258 257.207 258C239.304 258 224.79 243.486 224.79 225.582C224.79 207.678 239.304 193.164 257.207 193.164C275.111 193.164 289.625 207.678 289.625 225.582Z" fill={skinToneShadow}/>
<g id="Ellipse 2_2">
<mask id="path-56-inside-12_96_3150" fill="white">
<path d="M285.334 222.34C285.334 240.244 270.82 254.758 252.916 254.758C235.012 254.758 220.498 240.244 220.498 222.34C220.498 204.436 235.012 189.922 252.916 189.922C270.82 189.922 285.334 204.436 285.334 222.34Z"/>
</mask>
<path d="M285.334 222.34C285.334 240.244 270.82 254.758 252.916 254.758C235.012 254.758 220.498 240.244 220.498 222.34C220.498 204.436 235.012 189.922 252.916 189.922C270.82 189.922 285.334 204.436 285.334 222.34Z" fill="white" stroke="black" stroke-width="6" mask="url(#path-56-inside-12_96_3150)"/>
</g>
<path id="Subtract_2" fill-rule="evenodd" clip-rule="evenodd" d="M245.676 248.182C263.401 248.182 277.77 234.041 277.77 216.598C277.77 209.919 275.663 203.724 272.068 198.622C278.673 204.415 282.864 213.057 282.864 222.711C282.864 240.154 269.179 254.295 252.299 254.295C243.363 254.295 235.323 250.333 229.734 244.016C234.431 246.667 239.874 248.182 245.676 248.182Z" fill="#E1E0E0"/>
<g id="Ellipse 7_4">
<mask id="path-58-inside-13_96_3150" fill="white">
<path d="M285.334 222.34C285.334 240.244 270.82 254.758 252.916 254.758C235.012 254.758 220.498 240.244 220.498 222.34C220.498 204.436 235.012 189.922 252.916 189.922C270.82 189.922 285.334 204.436 285.334 222.34Z"/>
</mask>
<path d="M285.334 222.34C285.334 240.244 270.82 254.758 252.916 254.758C235.012 254.758 220.498 240.244 220.498 222.34C220.498 204.436 235.012 189.922 252.916 189.922C270.82 189.922 285.334 204.436 285.334 222.34Z" stroke="black" stroke-width="6" mask="url(#path-58-inside-13_96_3150)"/>
</g>
<ellipse id="Ellipse 4_2" cx="245.197" cy="226.353" rx="6.17484" ry="6.17484" fill="black"/>
</g>
</g>
        );
    };

    return (
<g id="neutral">
<g id="left eye_2">
<path id="Ellipse 6_3" d="M220.127 225.582C220.127 243.486 205.613 258 187.709 258C169.805 258 155.292 243.486 155.292 225.582C155.292 207.678 169.805 193.164 187.709 193.164C205.613 193.164 220.127 207.678 220.127 225.582Z" fill={skinToneShadow}/>
<g id="Ellipse 2_3">
<mask id="path-61-inside-14_96_3150" fill="white">
<path d="M215.836 222.34C215.836 240.244 201.322 254.758 183.418 254.758C165.514 254.758 151 240.244 151 222.34C151 204.436 165.514 189.922 183.418 189.922C201.322 189.922 215.836 204.436 215.836 222.34Z"/>
</mask>
<path d="M215.836 222.34C215.836 240.244 201.322 254.758 183.418 254.758C165.514 254.758 151 240.244 151 222.34C151 204.436 165.514 189.922 183.418 189.922C201.322 189.922 215.836 204.436 215.836 222.34Z" fill="white" stroke="black" stroke-width="6" mask="url(#path-61-inside-14_96_3150)"/>
</g>
<path id="Subtract_3" fill-rule="evenodd" clip-rule="evenodd" d="M176.178 248.182C193.903 248.182 208.272 234.041 208.272 216.597C208.272 209.919 206.165 203.724 202.571 198.622C209.175 204.415 213.366 213.057 213.366 222.711C213.366 240.154 199.681 254.295 182.801 254.295C173.865 254.295 165.825 250.333 160.236 244.016C164.933 246.666 170.375 248.182 176.178 248.182Z" fill="#E1E0E0"/>
<g id="Ellipse 7_5">
<mask id="path-63-inside-15_96_3150" fill="white">
<path d="M215.836 222.34C215.836 240.244 201.322 254.758 183.418 254.758C165.514 254.758 151 240.244 151 222.34C151 204.436 165.514 189.922 183.418 189.922C201.322 189.922 215.836 204.436 215.836 222.34Z"/>
</mask>
<path d="M215.836 222.34C215.836 240.244 201.322 254.758 183.418 254.758C165.514 254.758 151 240.244 151 222.34C151 204.436 165.514 189.922 183.418 189.922C201.322 189.922 215.836 204.436 215.836 222.34Z" stroke="black" stroke-width="6" mask="url(#path-63-inside-15_96_3150)"/>
</g>
<ellipse id="Ellipse 4_3" cx="175.699" cy="221.259" rx="6.17484" ry="6.17484" fill="black"/>
</g>
<g id="right eye_2">
<path id="Ellipse 6_4" d="M289.625 225.582C289.625 243.486 275.111 258 257.207 258C239.304 258 224.79 243.486 224.79 225.582C224.79 207.678 239.304 193.164 257.207 193.164C275.111 193.164 289.625 207.678 289.625 225.582Z" fill={skinToneShadow}/>
<g id="Ellipse 2_4">
<mask id="path-66-inside-16_96_3150" fill="white">
<path d="M285.334 222.34C285.334 240.244 270.82 254.758 252.916 254.758C235.012 254.758 220.498 240.244 220.498 222.34C220.498 204.436 235.012 189.922 252.916 189.922C270.82 189.922 285.334 204.436 285.334 222.34Z"/>
</mask>
<path d="M285.334 222.34C285.334 240.244 270.82 254.758 252.916 254.758C235.012 254.758 220.498 240.244 220.498 222.34C220.498 204.436 235.012 189.922 252.916 189.922C270.82 189.922 285.334 204.436 285.334 222.34Z" fill="white" stroke="black" stroke-width="6" mask="url(#path-66-inside-16_96_3150)"/>
</g>
<path id="Subtract_4" fill-rule="evenodd" clip-rule="evenodd" d="M245.676 248.182C263.401 248.182 277.77 234.041 277.77 216.598C277.77 209.919 275.663 203.724 272.068 198.622C278.673 204.415 282.864 213.057 282.864 222.711C282.864 240.154 269.179 254.295 252.299 254.295C243.363 254.295 235.323 250.333 229.734 244.016C234.431 246.667 239.874 248.182 245.676 248.182Z" fill="#E1E0E0"/>
<g id="Ellipse 7_6">
<mask id="path-68-inside-17_96_3150" fill="white">
<path d="M285.334 222.34C285.334 240.244 270.82 254.758 252.916 254.758C235.012 254.758 220.498 240.244 220.498 222.34C220.498 204.436 235.012 189.922 252.916 189.922C270.82 189.922 285.334 204.436 285.334 222.34Z"/>
</mask>
<path d="M285.334 222.34C285.334 240.244 270.82 254.758 252.916 254.758C235.012 254.758 220.498 240.244 220.498 222.34C220.498 204.436 235.012 189.922 252.916 189.922C270.82 189.922 285.334 204.436 285.334 222.34Z" stroke="black" stroke-width="6" mask="url(#path-68-inside-17_96_3150)"/>
</g>
<ellipse id="Ellipse 4_4" cx="245.197" cy="221.259" rx="6.17484" ry="6.17484" fill="black"/>
</g>
</g>
);
}

export default Eyes;