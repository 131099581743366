import React from 'react';
import {PFPContext} from './../../../context';

export const SunGlasses = () => {
    const {eyeAccessory} = React.useContext(PFPContext);

    if (eyeAccessory !== 'sunGlasses') return null;

    return (
        <path id="sun glasses" fill-rule="evenodd" clip-rule="evenodd" d="M1204.55 225.576H1266.67V243.454C1266.67 254.5 1257.71 263.454 1246.67 263.454H1216.97C1205.92 263.454 1196.97 254.5 1196.97 243.454V236.182H1190.91V243.454C1190.91 254.5 1181.95 263.454 1170.91 263.454H1141.21C1130.17 263.454 1121.21 254.5 1121.21 243.454V225.576L1184.85 225.576H1196.97H1204.55Z" fill="black"/>
    );
}

export default SunGlasses;
