import React from 'react';
import {PFPContext} from './../../context';

export const Mouth = () => {
    const {mouth} = React.useContext(PFPContext);

    if (mouth === 'smile') {
        return (
            <g id="smile">
<path id="Ellipse 17" d="M1200.42 342.667C1222.17 342.667 1239.87 325.311 1240.41 303.695C1240.44 302.568 1239.53 301.667 1238.42 301.667H1162.42C1161.32 301.667 1160.41 302.568 1160.44 303.695C1160.98 325.311 1178.68 342.667 1200.42 342.667Z" fill="black" stroke="black" stroke-width="2"/>
<path id="Intersect_7" fill-rule="evenodd" clip-rule="evenodd" d="M1238.12 312.695H1162.73C1162.12 310.427 1161.72 308.077 1161.54 305.667C1161.41 304.014 1162.77 302.667 1164.42 302.667H1236.42C1238.08 302.667 1239.44 304.014 1239.31 305.667C1239.13 308.077 1238.72 310.427 1238.12 312.695Z" fill="#EAE2DE"/>
<path id="Intersect_8" fill-rule="evenodd" clip-rule="evenodd" d="M1232.81 324.396C1225.81 334.813 1213.92 341.666 1200.42 341.666C1196.24 341.666 1192.2 341.006 1188.42 339.784C1187.47 339.324 1186.54 338.944 1185.94 338.881C1185.93 338.88 1185.92 338.879 1185.91 338.878C1185.68 338.783 1185.44 338.687 1185.21 338.588C1184.17 337.767 1184.07 335.382 1185.38 332.752C1186.5 330.524 1191.98 324.371 1199.87 324.395C1206.59 324.415 1226.95 324.401 1232.81 324.396Z" fill="#FC664C"/>
</g>
        );
    };

    return (
        <g id="Mouth-open">
<path id="Vector 4" d="M1190.91 296.788H1165.91C1162.12 296.788 1161.36 302.091 1165.91 305.879C1170.45 309.666 1178.03 312.697 1167.42 323.303C1165.15 325.576 1161.84 336.939 1171.97 336.939H1231.82C1239.39 336.939 1238.64 323.303 1231.82 319.515L1190.91 296.788Z" fill="black" stroke="black" stroke-width="3" stroke-linecap="round"/>
<g id="Intersect_4">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1235.87 324.06H1166.84C1164.69 327.413 1162.69 336.939 1171.97 336.939H1231.82C1237.42 336.939 1238.47 329.478 1235.87 324.06Z" fill="#DB807B"/>
<path d="M1166.84 324.06V322.56H1166.01L1165.57 323.251L1166.84 324.06ZM1235.87 324.06L1237.22 323.412L1236.82 322.56H1235.87V324.06ZM1166.84 325.56H1235.87V322.56H1166.84V325.56ZM1165.57 323.251C1164.34 325.17 1163.18 328.746 1163.63 331.965C1163.86 333.62 1164.54 335.285 1165.97 336.529C1167.39 337.774 1169.39 338.439 1171.97 338.439V335.439C1169.91 335.439 1168.68 334.913 1167.94 334.268C1167.19 333.62 1166.76 332.695 1166.6 331.549C1166.27 329.167 1167.18 326.304 1168.1 324.87L1165.57 323.251ZM1171.97 338.439H1231.82V335.439H1171.97V338.439ZM1231.82 338.439C1233.58 338.439 1235.07 337.841 1236.2 336.788C1237.31 335.758 1238 334.37 1238.37 332.896C1239.11 329.971 1238.65 326.394 1237.22 323.412L1234.52 324.709C1235.68 327.145 1236.01 330.007 1235.46 332.167C1235.2 333.235 1234.74 334.051 1234.16 334.589C1233.6 335.104 1232.86 335.439 1231.82 335.439V338.439Z" fill="black"/>
</g>
<g id="Intersect_5">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1231.82 319.515H1183.08C1178.2 319.515 1174.24 323.472 1174.24 328.353C1174.24 329.33 1175.03 330.121 1176.01 330.121H1237.22C1237.27 326.125 1235.37 321.486 1231.82 319.515Z" fill="white"/>
<path d="M1231.82 319.515L1232.55 318.204L1232.21 318.015H1231.82V319.515ZM1237.22 330.121V331.621H1238.7L1238.72 330.142L1237.22 330.121ZM1183.08 321.015H1231.82V318.015H1183.08V321.015ZM1175.74 328.353C1175.74 324.301 1179.03 321.015 1183.08 321.015V318.015C1177.37 318.015 1172.74 322.644 1172.74 328.353H1175.74ZM1176.01 328.621C1175.86 328.621 1175.74 328.501 1175.74 328.353H1172.74C1172.74 330.158 1174.21 331.621 1176.01 331.621V328.621ZM1237.22 328.621H1176.01V331.621H1237.22V328.621ZM1238.72 330.142C1238.78 325.787 1236.73 320.526 1232.55 318.204L1231.09 320.826C1234.01 322.447 1235.77 326.463 1235.72 330.1L1238.72 330.142Z" fill="black"/>
</g>
<g id="Intersect_6">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1176.01 296.788C1175.03 296.788 1174.24 297.579 1174.24 298.555C1174.24 303.437 1178.2 307.394 1183.08 307.394H1210L1190.91 296.788H1176.01Z" fill="white"/>
<path d="M1210 307.394V308.894H1215.79L1210.73 306.083L1210 307.394ZM1190.91 296.788L1191.64 295.476L1191.3 295.288H1190.91V296.788ZM1175.74 298.555C1175.74 298.408 1175.86 298.288 1176.01 298.288V295.288C1174.21 295.288 1172.74 296.751 1172.74 298.555H1175.74ZM1183.08 305.894C1179.03 305.894 1175.74 302.608 1175.74 298.555H1172.74C1172.74 304.265 1177.37 308.894 1183.08 308.894V305.894ZM1210 305.894H1183.08V308.894H1210V305.894ZM1210.73 306.083L1191.64 295.476L1190.18 298.099L1209.27 308.705L1210.73 306.083ZM1176.01 298.288H1190.91V295.288H1176.01V298.288Z" fill="black"/>
</g>
<path id="Vector 3" d="M1190.91 296.788H1165.91C1162.12 296.788 1161.36 302.091 1165.91 305.879C1170.45 309.666 1178.03 312.697 1167.42 323.303C1165.15 325.576 1161.84 336.939 1171.97 336.939H1231.82C1239.39 336.939 1238.64 323.303 1231.82 319.515L1190.91 296.788Z" stroke="black" stroke-width="3" stroke-linecap="round"/>
</g>
    );
}

export default Mouth;
