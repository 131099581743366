import React from 'react';
import {PFPContext} from './../../../context';

export const VR = () => {
    const {eyeAccessory} = React.useContext(PFPContext);

    if (eyeAccessory !== 'vr') return null;

    return (
        <g id="VR">
<rect id="Rectangle 26" x="1131.8" y="201.5" width="134.879" height="68.2121" rx="34.1061" fill="#2B2B2B" stroke="black" stroke-width="3"/>
<rect id="Rectangle 25" x="1121.2" y="201.5" width="134.879" height="68.2121" rx="34.1061" fill="#EBE2D9" stroke="black" stroke-width="3"/>
<rect id="Rectangle 24" x="1093.92" y="201.5" width="134.879" height="68.2121" rx="34.1061" fill="#EBE2D9" stroke="black" stroke-width="3"/>
<path id="Ellipse 12" d="M1243.95 235.606C1243.95 237.199 1242.97 237.894 1242.42 237.894C1241.87 237.894 1240.89 237.199 1240.89 235.606C1240.89 234.013 1241.87 233.318 1242.42 233.318C1242.97 233.318 1243.95 234.013 1243.95 235.606Z" fill="#2B2B2B" stroke="black" stroke-width="3"/>
</g>
    );
}

export default VR;
