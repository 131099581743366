import React from 'react';
import {PFPContext} from '../../../context';

export const Afro = () => {
    const {hair, skinToneShadow, hairColor} = React.useContext(PFPContext);

    if (hair !== 'afro') return null;

    return (
        <g id="afro">
<path id="Intersect_15" fill-rule="evenodd" clip-rule="evenodd" d="M1322.73 169.144H1121.21V191.872H1322.73V169.144Z" fill={skinToneShadow}/>
<g id="Group 23">
<g id="Group 14">
<g id="Union_6">
<mask id="path-228-inside-12_28_2171" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1144.18 39.9985C1129.82 39.9985 1118.18 51.6391 1118.18 65.9985V186.754H1278.35V239.004H1299.89V201.543C1299.8 196.903 1300.32 195.5 1308.84 195.5H1327.3V184.169H1327.27V65.9985C1327.27 51.6391 1315.63 39.9985 1301.27 39.9985H1144.18Z"/>
</mask>
<g filter="url(#filter28_i_28_2171)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1144.18 39.9985C1129.82 39.9985 1118.18 51.6391 1118.18 65.9985V186.754H1278.35V239.004H1299.89V201.543C1299.8 196.903 1300.32 195.5 1308.84 195.5H1327.3V184.169H1327.27V65.9985C1327.27 51.6391 1315.63 39.9985 1301.27 39.9985H1144.18Z" fill={hairColor}/>
</g>
<path d="M1118.18 186.754H1115.18V189.754H1118.18V186.754ZM1278.35 186.754H1281.35V183.754H1278.35V186.754ZM1278.35 239.004H1275.35V242.004H1278.35V239.004ZM1299.89 239.004V242.004H1302.89V239.004H1299.89ZM1299.89 201.543H1302.89V201.513L1302.89 201.483L1299.89 201.543ZM1327.3 195.5V198.5H1330.3V195.5H1327.3ZM1327.3 184.169H1330.3V181.169H1327.3V184.169ZM1327.27 184.169H1324.27V187.169H1327.27V184.169ZM1121.18 65.9985C1121.18 53.296 1131.48 42.9985 1144.18 42.9985V36.9985C1128.17 36.9985 1115.18 49.9823 1115.18 65.9985H1121.18ZM1121.18 186.754V65.9985H1115.18V186.754H1121.18ZM1278.35 183.754H1118.18V189.754H1278.35V183.754ZM1281.35 239.004V186.754H1275.35V239.004H1281.35ZM1299.89 236.004H1278.35V242.004H1299.89V236.004ZM1296.89 201.543V239.004H1302.89V201.543H1296.89ZM1308.84 192.5C1306.66 192.5 1304.84 192.587 1303.35 192.831C1301.89 193.071 1300.45 193.506 1299.28 194.423C1298.01 195.421 1297.4 196.72 1297.12 198.009C1296.87 199.176 1296.87 200.458 1296.89 201.603L1302.89 201.483C1302.87 200.308 1302.9 199.674 1302.98 199.273C1303.02 199.107 1303.05 199.061 1303.04 199.076C1303.04 199.084 1303.03 199.099 1303.01 199.116C1303 199.132 1302.99 199.14 1302.99 199.14C1303.01 199.126 1303.29 198.92 1304.32 198.752C1305.32 198.588 1306.76 198.5 1308.84 198.5V192.5ZM1327.3 192.5H1308.84V198.5H1327.3V192.5ZM1324.3 184.169V195.5H1330.3V184.169H1324.3ZM1327.27 187.169H1327.3V181.169H1327.27V187.169ZM1324.27 65.9985V184.169H1330.27V65.9985H1324.27ZM1301.27 42.9985C1313.98 42.9985 1324.27 53.296 1324.27 65.9985H1330.27C1330.27 49.9823 1317.29 36.9985 1301.27 36.9985V42.9985ZM1144.18 42.9985H1301.27V36.9985H1144.18V42.9985Z" fill="black" mask="url(#path-228-inside-12_28_2171)"/>
</g>
</g>
<rect id="Rectangle 37" x="1283.25" y="193.961" width="13.7054" height="42.1051" fill={hairColor}/>
</g>
</g>
    );
}

export default Afro;
