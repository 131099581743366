import React from 'react';
import {PFPContext} from './../../../context';

export const SunGlasses = () => {
    const {eyeAccessory} = React.useContext(PFPContext);

    if (eyeAccessory !== 'sunGlasses') return null;

    return (
<path id="sun glasses" fill-rule="evenodd" clip-rule="evenodd" d="M143.481 217.287H208.486H214.676H220.867H228.606H292.062V235.971C292.062 247.016 283.108 255.971 272.062 255.971H240.867C229.822 255.971 220.867 247.016 220.867 235.971V228.118H214.676V235.971C214.676 247.016 205.722 255.971 194.676 255.971H163.481C152.436 255.971 143.481 247.016 143.481 235.971V217.287Z" fill="black"/>
    );
}

export default SunGlasses;
