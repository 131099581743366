import React from 'react';
import {PFPContext} from './../../../context';

export const MedHair = () => {
    const {hair, hairColor, skinToneShadow} = React.useContext(PFPContext);

    if (hair !== 'medHair') return null;

    return (
        <g id="med hair_2">
<g id="hair">
<path id="Intersect_9" fill-rule="evenodd" clip-rule="evenodd" d="M349.328 159.22H143.481V182.431H349.328V159.22Z" fill={skinToneShadow}/>
<g id="Union_5">
<mask id="path-99-inside-23_96_3150" fill="white">
<path d="M364.851 178.164H364.803V316.168C364.803 324.996 357.645 332.153 348.814 332.153H312.038C303.207 332.153 296.048 324.996 296.048 316.168V178.164H144.147C136.823 178.32 134.668 180.387 134.554 187.755V332.153C128.372 332.153 123.361 327.143 123.361 320.963V174.717C123.361 108.047 177.42 54.0001 244.106 54.0001C310.792 54.0001 364.851 108.047 364.851 174.717V178.164Z"/>
</mask>
<path d="M364.851 178.164H364.803V316.168C364.803 324.996 357.645 332.153 348.814 332.153H312.038C303.207 332.153 296.048 324.996 296.048 316.168V178.164H144.147C136.823 178.32 134.668 180.387 134.554 187.755V332.153C128.372 332.153 123.361 327.143 123.361 320.963V174.717C123.361 108.047 177.42 54.0001 244.106 54.0001C310.792 54.0001 364.851 108.047 364.851 174.717V178.164Z" fill={hairColor} stroke="black" stroke-width="6" mask="url(#path-99-inside-23_96_3150)"/>
</g>
<path id="Intersect_10" fill-rule="evenodd" clip-rule="evenodd" d="M343.048 277.161C342.712 294.117 342.363 311.765 342.363 330.018C342.363 330.823 342.464 331.533 342.656 332.153H348.814C357.644 332.153 364.803 324.996 364.803 316.168V178.164H364.851V174.717C364.851 115.132 321.671 65.6305 264.89 55.7817L244.083 56.9096C346.878 83.7778 345.26 165.465 343.048 277.161Z" fill="black" fill-opacity="0.25"/>
<path id="Vector 20" d="M296.705 176.675H341.589" stroke="black" stroke-width="3" stroke-linecap="round"/>
</g>
<path id="Vector 21" d="M209.705 176.292C209.705 106.753 219.319 77.7989 243.283 57.1973" stroke="black" stroke-width="3" stroke-linecap="round"/>
<g id="Group 13">
<path id="Line 3_2" d="M340.089 327.697L340.089 329.197L343.089 329.197L343.089 327.697L340.089 327.697ZM343.089 301.392C343.089 300.563 342.417 299.892 341.589 299.892C340.76 299.892 340.089 300.563 340.089 301.392L343.089 301.392ZM343.089 327.697L343.089 301.392L340.089 301.392L340.089 327.697L343.089 327.697Z" fill="black"/>
<path id="Line 4_2" d="M313.778 327.697L313.778 329.197L316.778 329.197L316.778 327.697L313.778 327.697ZM316.778 275.086C316.778 274.258 316.106 273.586 315.278 273.586C314.449 273.586 313.778 274.258 313.778 275.086L316.778 275.086ZM316.778 327.697L316.778 275.086L313.778 275.086L313.778 327.697L316.778 327.697Z" fill="black"/>
<path id="Vector 23" d="M153.589 176.056L153.589 177.556L156.589 177.556L156.589 176.056L153.589 176.056ZM166.516 114.005C166.857 113.25 166.522 112.362 165.767 112.021C165.012 111.68 164.123 112.015 163.782 112.77L166.516 114.005ZM182.222 176.056L182.222 177.556L185.222 177.556L185.222 176.056L182.222 176.056ZM195.149 114.005C195.49 113.25 195.154 112.362 194.399 112.021C193.644 111.68 192.756 112.015 192.415 112.77L195.149 114.005ZM156.589 176.056C156.589 156.677 155.834 137.653 166.516 114.005L163.782 112.77C152.796 137.091 153.589 156.751 153.589 176.056L156.589 176.056ZM185.222 176.056C185.222 156.677 184.467 137.653 195.149 114.005L192.415 112.77C181.429 137.091 182.222 156.751 182.222 176.056L185.222 176.056Z" fill="black"/>
<path id="Vector 24" d="M237.952 176.056L237.952 177.556L240.952 177.556L240.952 176.056L237.952 176.056ZM250.879 114.005C251.22 113.25 250.884 112.362 250.129 112.021C249.374 111.68 248.486 112.015 248.145 112.77L250.879 114.005ZM266.585 176.056L266.585 177.556L269.585 177.556L269.585 176.056L266.585 176.056ZM279.512 114.005C279.853 113.25 279.517 112.362 278.762 112.021C278.007 111.68 277.119 112.015 276.778 112.77L279.512 114.005ZM240.952 176.056C240.952 156.677 240.197 137.653 250.879 114.005L248.145 112.77C237.159 137.091 237.952 156.751 237.952 176.056L240.952 176.056ZM269.585 176.056C269.585 156.677 268.83 137.653 279.512 114.005L276.778 112.77C265.792 137.091 266.585 156.751 266.585 176.056L269.585 176.056Z" fill="black"/>
</g>
</g>
    );
}

export default MedHair;
