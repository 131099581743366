import React from 'react';
import {PFPContext} from './../../context';

export const Eyes = () => {
    const {eyes, skinToneShadow} = React.useContext(PFPContext);

    if (eyes === 'up') {
        return (
            <g id="up">
<g id="left eye_3">
<path id="Ellipse 6_5" d="M1196.25 233.698C1196.25 251.23 1182.04 265.442 1164.51 265.442C1146.98 265.442 1132.77 251.23 1132.77 233.698C1132.77 216.167 1146.98 201.955 1164.51 201.955C1182.04 201.955 1196.25 216.167 1196.25 233.698Z" fill={skinToneShadow}/>
<path id="Ellipse 2_5" d="M1190.54 230.524C1190.54 247.227 1177.01 260.767 1160.31 260.767C1143.61 260.767 1130.07 247.227 1130.07 230.524C1130.07 213.821 1143.61 200.281 1160.31 200.281C1177.01 200.281 1190.54 213.821 1190.54 230.524Z" fill="white" stroke="black" stroke-width="3"/>
<path id="Subtract_5" fill-rule="evenodd" clip-rule="evenodd" d="M1153.22 255.828C1170.57 255.828 1184.64 241.981 1184.64 224.901C1184.64 218.361 1182.58 212.296 1179.06 207.3C1185.52 212.973 1189.63 221.435 1189.63 230.887C1189.63 247.967 1176.23 261.814 1159.7 261.814C1150.96 261.814 1143.09 257.934 1137.61 251.749C1142.21 254.344 1147.54 255.828 1153.22 255.828Z" fill="#E1E0E0"/>
<path id="Ellipse 7_7" d="M1190.54 230.524C1190.54 247.227 1177.01 260.767 1160.31 260.767C1143.61 260.767 1130.07 247.227 1130.07 230.524C1130.07 213.821 1143.61 200.281 1160.31 200.281C1177.01 200.281 1190.54 213.821 1190.54 230.524Z" stroke="black" stroke-width="3"/>
<ellipse id="Ellipse 4_5" cx="1152.75" cy="224.477" rx="6.04491" ry="6.04633" fill="black"/>
</g>
<g id="right eye_3">
<path id="Ellipse 6_6" d="M1264.28 233.698C1264.28 251.23 1250.07 265.442 1232.55 265.442C1215.02 265.442 1200.81 251.23 1200.81 233.698C1200.81 216.167 1215.02 201.955 1232.55 201.955C1250.07 201.955 1264.28 216.167 1264.28 233.698Z" fill={skinToneShadow}/>
<path id="Ellipse 2_6" d="M1258.58 230.524C1258.58 247.227 1245.04 260.767 1228.34 260.767C1211.65 260.767 1198.11 247.227 1198.11 230.524C1198.11 213.821 1211.65 200.281 1228.34 200.281C1245.04 200.281 1258.58 213.821 1258.58 230.524Z" fill="white" stroke="black" stroke-width="3"/>
<path id="Subtract_6" fill-rule="evenodd" clip-rule="evenodd" d="M1221.26 255.828C1238.61 255.828 1252.67 241.982 1252.67 224.901C1252.67 218.361 1250.61 212.295 1247.09 207.299C1253.56 212.972 1257.66 221.434 1257.66 230.887C1257.66 247.967 1244.27 261.814 1227.74 261.814C1218.99 261.814 1211.12 257.934 1205.65 251.749C1210.25 254.344 1215.58 255.828 1221.26 255.828Z" fill="#E1E0E0"/>
<path id="Ellipse 7_8" d="M1258.58 230.524C1258.58 247.227 1245.04 260.767 1228.34 260.767C1211.65 260.767 1198.11 247.227 1198.11 230.524C1198.11 213.821 1211.65 200.281 1228.34 200.281C1245.04 200.281 1258.58 213.821 1258.58 230.524Z" stroke="black" stroke-width="3"/>
<ellipse id="Ellipse 4_6" cx="1220.79" cy="224.477" rx="6.04491" ry="6.04633" fill="black"/>
</g>
</g>
        );
    };

    if (eyes === 'down') {
        return (
            <g id="down">
<g id="left eye">
<path id="Ellipse 6" d="M1196.25 233.698C1196.25 251.23 1182.04 265.442 1164.51 265.442C1146.98 265.442 1132.77 251.23 1132.77 233.698C1132.77 216.167 1146.98 201.955 1164.51 201.955C1182.04 201.955 1196.25 216.167 1196.25 233.698Z" fill={skinToneShadow}/>
<path id="Ellipse 2" d="M1190.54 230.524C1190.54 247.227 1177.01 260.767 1160.31 260.767C1143.61 260.767 1130.07 247.227 1130.07 230.524C1130.07 213.821 1143.61 200.281 1160.31 200.281C1177.01 200.281 1190.54 213.821 1190.54 230.524Z" fill="white" stroke="black" stroke-width="3"/>
<path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M1153.22 255.828C1170.57 255.828 1184.64 241.981 1184.64 224.901C1184.64 218.361 1182.58 212.296 1179.06 207.3C1185.52 212.973 1189.63 221.435 1189.63 230.887C1189.63 247.967 1176.23 261.814 1159.7 261.814C1150.96 261.814 1143.09 257.934 1137.61 251.749C1142.21 254.344 1147.54 255.828 1153.22 255.828Z" fill="#E1E0E0"/>
<path id="Ellipse 7_3" d="M1190.54 230.524C1190.54 247.227 1177.01 260.767 1160.31 260.767C1143.61 260.767 1130.07 247.227 1130.07 230.524C1130.07 213.821 1143.61 200.281 1160.31 200.281C1177.01 200.281 1190.54 213.821 1190.54 230.524Z" stroke="black" stroke-width="3"/>
<ellipse id="Ellipse 4" cx="1152.75" cy="234.454" rx="6.04491" ry="6.04632" fill="black"/>
</g>
<g id="right eye">
<path id="Ellipse 6_2" d="M1264.28 233.698C1264.28 251.23 1250.07 265.442 1232.55 265.442C1215.02 265.442 1200.81 251.23 1200.81 233.698C1200.81 216.167 1215.02 201.955 1232.55 201.955C1250.07 201.955 1264.28 216.167 1264.28 233.698Z" fill={skinToneShadow}/>
<path id="Ellipse 2_2" d="M1258.58 230.524C1258.58 247.227 1245.04 260.767 1228.34 260.767C1211.65 260.767 1198.11 247.227 1198.11 230.524C1198.11 213.821 1211.65 200.281 1228.34 200.281C1245.04 200.281 1258.58 213.821 1258.58 230.524Z" fill="white" stroke="black" stroke-width="3"/>
<path id="Subtract_2" fill-rule="evenodd" clip-rule="evenodd" d="M1221.26 255.828C1238.61 255.828 1252.67 241.982 1252.67 224.901C1252.67 218.361 1250.61 212.295 1247.09 207.299C1253.56 212.972 1257.66 221.434 1257.66 230.887C1257.66 247.967 1244.27 261.814 1227.74 261.814C1218.99 261.814 1211.12 257.934 1205.65 251.749C1210.25 254.344 1215.58 255.828 1221.26 255.828Z" fill="#E1E0E0"/>
<path id="Ellipse 7_4" d="M1258.58 230.524C1258.58 247.227 1245.04 260.767 1228.34 260.767C1211.65 260.767 1198.11 247.227 1198.11 230.524C1198.11 213.821 1211.65 200.281 1228.34 200.281C1245.04 200.281 1258.58 213.821 1258.58 230.524Z" stroke="black" stroke-width="3"/>
<ellipse id="Ellipse 4_2" cx="1220.79" cy="234.454" rx="6.04491" ry="6.04632" fill="black"/>
</g>
</g>
        );
    };

    return (
<g id="neutral">
<g id="left eye_2">
<path id="Ellipse 6_3" d="M1196.25 233.698C1196.25 251.23 1182.04 265.442 1164.51 265.442C1146.98 265.442 1132.77 251.23 1132.77 233.698C1132.77 216.167 1146.98 201.955 1164.51 201.955C1182.04 201.955 1196.25 216.167 1196.25 233.698Z" fill={skinToneShadow}/>
<path id="Ellipse 2_3" d="M1190.54 230.524C1190.54 247.227 1177.01 260.767 1160.31 260.767C1143.61 260.767 1130.07 247.227 1130.07 230.524C1130.07 213.821 1143.61 200.281 1160.31 200.281C1177.01 200.281 1190.54 213.821 1190.54 230.524Z" fill="white" stroke="black" stroke-width="3"/>
<path id="Subtract_3" fill-rule="evenodd" clip-rule="evenodd" d="M1153.22 255.828C1170.57 255.828 1184.64 241.981 1184.64 224.901C1184.64 218.361 1182.58 212.296 1179.06 207.3C1185.52 212.973 1189.63 221.435 1189.63 230.887C1189.63 247.967 1176.23 261.814 1159.7 261.814C1150.96 261.814 1143.09 257.934 1137.61 251.749C1142.21 254.344 1147.54 255.828 1153.22 255.828Z" fill="#E1E0E0"/>
<path id="Ellipse 7_5" d="M1190.54 230.524C1190.54 247.227 1177.01 260.767 1160.31 260.767C1143.61 260.767 1130.07 247.227 1130.07 230.524C1130.07 213.821 1143.61 200.281 1160.31 200.281C1177.01 200.281 1190.54 213.821 1190.54 230.524Z" stroke="black" stroke-width="3"/>
<ellipse id="Ellipse 4_3" cx="1152.75" cy="229.466" rx="6.04491" ry="6.04633" fill="black"/>
</g>
<g id="right eye_2">
<path id="Ellipse 6_4" d="M1264.28 233.698C1264.28 251.23 1250.07 265.442 1232.55 265.442C1215.02 265.442 1200.81 251.23 1200.81 233.698C1200.81 216.167 1215.02 201.955 1232.55 201.955C1250.07 201.955 1264.28 216.167 1264.28 233.698Z" fill={skinToneShadow}/>
<path id="Ellipse 2_4" d="M1258.58 230.524C1258.58 247.227 1245.04 260.767 1228.34 260.767C1211.65 260.767 1198.11 247.227 1198.11 230.524C1198.11 213.821 1211.65 200.281 1228.34 200.281C1245.04 200.281 1258.58 213.821 1258.58 230.524Z" fill="white" stroke="black" stroke-width="3"/>
<path id="Subtract_4" fill-rule="evenodd" clip-rule="evenodd" d="M1221.26 255.828C1238.61 255.828 1252.67 241.982 1252.67 224.901C1252.67 218.361 1250.61 212.295 1247.09 207.299C1253.56 212.972 1257.66 221.434 1257.66 230.887C1257.66 247.967 1244.27 261.814 1227.74 261.814C1218.99 261.814 1211.12 257.934 1205.65 251.749C1210.25 254.344 1215.58 255.828 1221.26 255.828Z" fill="#E1E0E0"/>
<path id="Ellipse 7_6" d="M1258.58 230.524C1258.58 247.227 1245.04 260.767 1228.34 260.767C1211.65 260.767 1198.11 247.227 1198.11 230.524C1198.11 213.821 1211.65 200.281 1228.34 200.281C1245.04 200.281 1258.58 213.821 1258.58 230.524Z" stroke="black" stroke-width="3"/>
<ellipse id="Ellipse 4_4" cx="1220.79" cy="229.466" rx="6.04491" ry="6.04633" fill="black"/>
</g>
</g>
);
}

export default Eyes;