import React from 'react';
import {PFPContext} from '../../context';

export const FacialHair = () => {
    const {facialHair, facialHairColor} = React.useContext(PFPContext);

    if (facialHair === 'none') {
        return null;
    };

    return (
        <g id="beard">
<mask id="path-48-inside-9_96_3150" fill="white">
<path fill-rule="evenodd" clip-rule="evenodd" d="M353.971 255.97V268.324C353.971 327.304 306.158 375.117 247.179 375.117C188.199 375.117 140.386 327.304 140.386 268.324V245.139H142.708V255.197C142.708 263.707 151.22 273.765 157.411 273.765H288.193C299.801 273.765 304 264.481 304 255.197V216H326V248.234C326 252.953 326.112 255.97 335.398 255.97H353.971Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M353.971 255.97V268.324C353.971 327.304 306.158 375.117 247.179 375.117C188.199 375.117 140.386 327.304 140.386 268.324V245.139H142.708V255.197C142.708 263.707 151.22 273.765 157.411 273.765H288.193C299.801 273.765 304 264.481 304 255.197V216H326V248.234C326 252.953 326.112 255.97 335.398 255.97H353.971Z" fill={facialHairColor}/>
<path d="M353.971 255.97H356.971V252.97H353.971V255.97ZM140.386 245.139V242.139H137.386V245.139H140.386ZM142.708 245.139H145.708V242.139H142.708V245.139ZM304 216V213H301V216H304ZM326 216H329V213H326V216ZM350.971 255.97V268.324H356.971V255.97H350.971ZM350.971 268.324C350.971 325.647 304.502 372.117 247.179 372.117V378.117C307.815 378.117 356.971 328.961 356.971 268.324H350.971ZM247.179 372.117C189.855 372.117 143.386 325.647 143.386 268.324H137.386C137.386 328.961 186.542 378.117 247.179 378.117V372.117ZM143.386 268.324V245.139H137.386V268.324H143.386ZM142.708 242.139H140.386V248.139H142.708V242.139ZM145.708 255.197V245.139H139.708V255.197H145.708ZM157.411 270.765C156.642 270.765 155.515 270.434 154.127 269.539C152.771 268.665 151.356 267.371 150.068 265.783C147.44 262.542 145.708 258.547 145.708 255.197H139.708C139.708 260.357 142.232 265.646 145.408 269.562C147.021 271.552 148.895 273.305 150.876 274.582C152.825 275.839 155.084 276.765 157.411 276.765V270.765ZM288.193 270.765H157.411V276.765H288.193V270.765ZM301 255.197C301 259.452 300.028 263.407 298.013 266.212C296.1 268.875 293.073 270.765 288.193 270.765V276.765C294.921 276.765 299.797 274.013 302.886 269.713C305.873 265.555 307 260.226 307 255.197H301ZM301 216V255.197H307V216H301ZM326 213H304V219H326V213ZM329 248.234V216H323V248.234H329ZM335.398 252.97C330.799 252.97 329.705 252.157 329.464 251.885C329.366 251.774 329.222 251.552 329.123 250.947C329.014 250.284 329 249.461 329 248.234H323C323 249.366 323 250.69 323.202 251.92C323.414 253.208 323.879 254.626 324.97 255.859C327.106 258.275 330.712 258.97 335.398 258.97V252.97ZM353.971 252.97H335.398V258.97H353.971V252.97Z" fill="black" mask="url(#path-48-inside-9_96_3150)"/>
</g>
    );
}

export default FacialHair;
