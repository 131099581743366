import React from 'react';
import {PFPContext} from './../../../context';

export const MedHair = () => {
    const {hair, hairColor, skinToneShadow} = React.useContext(PFPContext);

    if (hair !== 'medHair') return null;

    return (
        <g id="med hair_2">
<g id="hair">
<path id="Intersect_9" fill-rule="evenodd" clip-rule="evenodd" d="M1322.73 168.718H1121.21V191.445H1322.73V168.718Z" fill={skinToneShadow}/>
<path id="Union_5" d="M1110.97 196.659V336.434C1106.46 335.715 1103.02 331.807 1103.02 327.095V183.892C1103.02 119.438 1155.27 67.1875 1219.72 67.1875C1284.17 67.1875 1336.42 119.438 1336.42 183.892V185.767H1336.38V187.267V322.399C1336.38 330.215 1330.04 336.552 1322.22 336.552H1286.22C1278.41 336.552 1272.07 330.215 1272.07 322.399V187.267V185.767H1270.57H1121.86H1121.85L1121.83 185.768C1118.21 185.845 1115.35 186.378 1113.46 188.25C1111.57 190.128 1111.03 192.987 1110.97 196.636L1110.97 196.648V196.659Z" fill={hairColor} stroke="black" stroke-width="3"/>
<path id="Intersect_10" fill-rule="evenodd" clip-rule="evenodd" d="M1316.58 284.204C1316.25 300.807 1315.91 318.088 1315.91 335.961C1315.91 336.75 1316.01 337.444 1316.2 338.052H1322.22C1330.87 338.052 1337.88 331.044 1337.88 322.399V187.267H1337.92V183.892C1337.92 125.547 1295.65 77.0758 1240.07 67.432L1219.7 68.5364C1320.33 94.8455 1318.75 174.832 1316.58 284.204Z" fill="black" fill-opacity="0.25"/>
<path id="Vector 20" d="M1271.21 185.809H1315.15" stroke="black" stroke-width="3" stroke-linecap="round"/>
</g>
<path id="Vector 21" d="M1186.04 185.434C1186.04 117.343 1195.45 88.9909 1218.91 68.818" stroke="black" stroke-width="3" stroke-linecap="round"/>
<g id="Group 13">
<path id="Line 3_2" d="M1313.65 333.688L1313.65 335.188L1316.65 335.188L1316.65 333.688L1313.65 333.688ZM1316.65 307.93C1316.65 307.102 1315.98 306.43 1315.15 306.43C1314.32 306.43 1313.65 307.102 1313.65 307.93L1316.65 307.93ZM1316.65 333.688L1316.65 307.93L1313.65 307.93L1313.65 333.688L1316.65 333.688Z" fill="black"/>
<path id="Line 4_2" d="M1287.89 333.688L1287.89 335.188L1290.89 335.188L1290.89 333.688L1287.89 333.688ZM1290.89 282.173C1290.89 281.344 1290.22 280.673 1289.39 280.673C1288.57 280.673 1287.89 281.344 1287.89 282.173L1290.89 282.173ZM1290.89 333.688L1290.89 282.173L1287.89 282.173L1287.89 333.688L1290.89 333.688Z" fill="black"/>
<path id="Vector 23" d="M1131.08 185.203L1131.08 186.703L1134.08 186.703L1134.08 185.203L1131.08 185.203ZM1143.79 124.457C1144.13 123.702 1143.8 122.813 1143.04 122.472C1142.29 122.131 1141.4 122.467 1141.06 123.222L1143.79 124.457ZM1159.11 185.203L1159.11 186.703L1162.11 186.703L1162.11 185.203L1159.11 185.203ZM1171.82 124.457C1172.16 123.702 1171.83 122.813 1171.07 122.472C1170.32 122.131 1169.43 122.467 1169.09 123.222L1171.82 124.457ZM1134.08 185.203C1134.08 166.227 1133.34 147.605 1143.79 124.457L1141.06 123.222C1130.3 147.043 1131.08 166.301 1131.08 185.203L1134.08 185.203ZM1162.11 185.203C1162.11 166.227 1161.37 147.605 1171.82 124.457L1169.09 123.222C1158.33 147.043 1159.11 166.301 1159.11 185.203L1162.11 185.203Z" fill="black"/>
<path id="Vector 24" d="M1213.66 185.203L1213.66 186.703L1216.66 186.703L1216.66 185.203L1213.66 185.203ZM1226.38 124.457C1226.72 123.702 1226.38 122.813 1225.63 122.472C1224.87 122.131 1223.99 122.467 1223.64 123.222L1226.38 124.457ZM1241.69 185.203L1241.69 186.703L1244.69 186.703L1244.69 185.203L1241.69 185.203ZM1254.41 124.457C1254.75 123.702 1254.41 122.813 1253.66 122.472C1252.9 122.131 1252.02 122.467 1251.68 123.222L1254.41 124.457ZM1216.66 185.203C1216.66 166.227 1215.92 147.605 1226.38 124.457L1223.64 123.222C1212.89 147.043 1213.66 166.301 1213.66 185.203L1216.66 185.203ZM1244.69 185.203C1244.69 166.227 1243.95 147.605 1254.41 124.457L1251.68 123.222C1240.92 147.043 1241.69 166.301 1241.69 185.203L1244.69 185.203Z" fill="black"/>
</g>
</g>
    );
}

export default MedHair;
