import React from 'react';

import SVGInner from './SVG/Banner/SVGInner';
import Defs from './SVG/Banner/Defs';
import {BannerContext} from './context';

export const Banner = ({bannerText, bannerRef, bannerColor}) => {
    const {bannerScale} = React.useContext(BannerContext);

    return (
        <>
        <svg ref={bannerRef} id="hidden-banner" width="1500" height="500" viewBox="0 0 1500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
            <SVGInner />
            <Defs />
        </svg>
        <svg style={{transform: `scale(${bannerScale})`}} id="banner" width="1500" height="500" viewBox="0 0 1500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
            <SVGInner isShowing={true} />
            <Defs />
      </svg>
      </>
    );
}

export default Banner;