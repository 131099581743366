import React from 'react';
import {PFPContext} from './../../context';

export const Mouth = () => {
    const {mouth} = React.useContext(PFPContext);

    if (mouth === 'smile') {
        return (
            <g id="smile">
<g id="Ellipse 17">
<mask id="path-95-outside-22_96_3150" maskUnits="userSpaceOnUse" x="180" y="289" width="82" height="43" fill="black">
<rect fill="white" x="180" y="289" width="82" height="43"/>
<path d="M221 330C242.204 330 259.455 313.078 259.987 292.003C260.001 291.451 259.552 291 259 291H183C182.448 291 181.999 291.451 182.013 292.003C182.545 313.078 199.796 330 221 330Z"/>
</mask>
<path d="M221 330C242.204 330 259.455 313.078 259.987 292.003C260.001 291.451 259.552 291 259 291H183C182.448 291 181.999 291.451 182.013 292.003C182.545 313.078 199.796 330 221 330Z" fill="black"/>
<path d="M221 330C242.204 330 259.455 313.078 259.987 292.003C260.001 291.451 259.552 291 259 291H183C182.448 291 181.999 291.451 182.013 292.003C182.545 313.078 199.796 330 221 330Z" stroke="black" stroke-width="4" mask="url(#path-95-outside-22_96_3150)"/>
</g>
<path id="Intersect_7" fill-rule="evenodd" clip-rule="evenodd" d="M258.699 301.029H183.302C182.7 298.76 182.297 296.411 182.114 294C181.988 292.348 183.343 291 185 291H257C258.657 291 260.012 292.348 259.886 294C259.703 296.411 259.301 298.76 258.699 301.029Z" fill="#EAE2DE"/>
<path id="Intersect_8" fill-rule="evenodd" clip-rule="evenodd" d="M253.39 312.73C246.388 323.146 234.494 330 221 330C216.811 330 212.776 329.339 208.994 328.117C208.049 327.657 207.117 327.277 206.514 327.214C206.506 327.213 206.498 327.212 206.49 327.211C206.255 327.117 206.02 327.02 205.787 326.921C204.745 326.101 204.642 323.715 205.957 321.085C207.071 318.857 212.558 312.705 220.443 312.728C227.164 312.748 247.527 312.734 253.39 312.73Z" fill="#FC664C"/>
</g>
        );
    };

    return (
        <g id="Mouth-open">
<path id="Vector 4" d="M214.677 290.012H189.139C185.27 290.012 184.496 295.428 189.139 299.296C193.783 303.165 201.521 306.26 190.687 317.091C188.365 319.412 184.979 331.017 195.33 331.017H256.465C264.203 331.017 263.43 317.091 256.465 313.223L214.677 290.012Z" fill="black" stroke="black" stroke-width="3" stroke-linecap="round"/>
<g id="Intersect_4">
<path fill-rule="evenodd" clip-rule="evenodd" d="M260.604 317.865H190.085C187.891 321.289 185.852 331.017 195.33 331.017H256.465C262.189 331.017 263.256 323.397 260.604 317.865Z" fill="#DB807B"/>
<path d="M190.085 317.865V316.365H189.265L188.822 317.055L190.085 317.865ZM260.604 317.865L261.957 317.216L261.549 316.365H260.604V317.865ZM190.085 319.365H260.604V316.365H190.085V319.365ZM188.822 317.055C187.57 319.009 186.386 322.654 186.845 325.933C187.081 327.617 187.769 329.311 189.218 330.574C190.669 331.84 192.705 332.517 195.33 332.517V329.517C193.216 329.517 191.953 328.978 191.189 328.313C190.424 327.645 189.981 326.693 189.816 325.517C189.474 323.076 190.406 320.144 191.348 318.674L188.822 317.055ZM195.33 332.517H256.465V329.517H195.33V332.517ZM256.465 332.517C258.257 332.517 259.766 331.909 260.919 330.84C262.049 329.793 262.754 328.382 263.131 326.88C263.877 323.901 263.413 320.255 261.957 317.216L259.252 318.513C260.447 321.006 260.775 323.937 260.221 326.151C259.946 327.246 259.477 328.086 258.88 328.64C258.306 329.172 257.535 329.517 256.465 329.517V332.517Z" fill="black"/>
</g>
<g id="Intersect_5">
<path fill-rule="evenodd" clip-rule="evenodd" d="M256.465 313.223H206.678C201.693 313.223 197.651 317.264 197.651 322.249C197.651 323.246 198.46 324.054 199.457 324.054H261.981C262.037 319.973 260.09 315.236 256.465 313.223Z" fill="white"/>
<path d="M256.465 313.223L257.193 311.911L256.853 311.723H256.465V313.223ZM261.981 324.054V325.554H263.461L263.481 324.075L261.981 324.054ZM206.678 314.723H256.465V311.723H206.678V314.723ZM199.151 322.249C199.151 318.092 202.521 314.723 206.678 314.723V311.723C200.864 311.723 196.151 316.435 196.151 322.249H199.151ZM199.457 322.554C199.288 322.554 199.151 322.417 199.151 322.249H196.151C196.151 324.074 197.631 325.554 199.457 325.554V322.554ZM261.981 322.554H199.457V325.554H261.981V322.554ZM263.481 324.075C263.542 319.635 261.449 314.275 257.193 311.911L255.737 314.534C258.73 316.197 260.533 320.311 260.481 324.033L263.481 324.075Z" fill="black"/>
</g>
<g id="Intersect_6">
<path fill-rule="evenodd" clip-rule="evenodd" d="M199.457 290.012C198.46 290.012 197.651 290.82 197.651 291.817C197.651 296.803 201.693 300.844 206.678 300.844H234.178L214.676 290.012H199.457Z" fill="white"/>
<path d="M234.178 300.844V302.344H239.968L234.906 299.532L234.178 300.844ZM214.676 290.012L215.405 288.701L215.065 288.512H214.676V290.012ZM199.151 291.817C199.151 291.649 199.288 291.512 199.457 291.512V288.512C197.631 288.512 196.151 289.992 196.151 291.817H199.151ZM206.678 299.344C202.521 299.344 199.151 295.974 199.151 291.817H196.151C196.151 297.631 200.864 302.344 206.678 302.344V299.344ZM234.178 299.344H206.678V302.344H234.178V299.344ZM234.906 299.532L215.405 288.701L213.948 291.324L233.449 302.155L234.906 299.532ZM199.457 291.512H214.676V288.512H199.457V291.512Z" fill="black"/>
</g>
<path id="Vector 3" d="M214.677 290.012H189.139C185.27 290.012 184.496 295.428 189.139 299.296C193.783 303.165 201.521 306.26 190.687 317.091C188.365 319.412 184.979 331.017 195.33 331.017H256.465C264.203 331.017 263.43 317.091 256.465 313.223L214.677 290.012Z" stroke="black" stroke-width="3" stroke-linecap="round"/>
</g>
    );
}

export default Mouth;
