import React from 'react';
import {PFPContext} from './../../../../context';

export const MedHair = () => {
    const {hair, hairColor} = React.useContext(PFPContext);

    if (hair !== 'medHair') return null;

    return (
        <g id="med hair">
<path id="Rectangle 23" d="M124.861 174.795H307.34V333.386H133.361C128.667 333.386 124.861 329.58 124.861 324.886V174.795Z" fill={hairColor} stroke="black" stroke-width="3"/>
<path id="Rectangle 22" d="M124.861 174.795H307.34V333.386H133.361C128.667 333.386 124.861 329.58 124.861 324.886V174.795Z" fill="black" fill-opacity="0.25" stroke="black" stroke-width="3"/>
</g>
    );
}

export default MedHair;
