import React from 'react';
import {PFPContext} from './../../../context';

export const VR = () => {
    const {eyeAccessory} = React.useContext(PFPContext);

    if (eyeAccessory !== 'vr') return null;

    return (
        <g id="VR">
<rect id="Rectangle 26" x="154.268" y="192.667" width="137.842" height="69.7257" rx="34.8628" fill="#2B2B2B" stroke="black" stroke-width="3"/>
<rect id="Rectangle 25" x="143.434" y="192.667" width="137.842" height="69.7257" rx="34.8628" fill="#EBE2D9" stroke="black" stroke-width="3"/>
<rect id="Rectangle 24" x="115.575" y="192.667" width="137.842" height="69.7257" rx="34.8628" fill="#EBE2D9" stroke="black" stroke-width="3"/>
<path id="Ellipse 12" d="M268.894 227.53C268.894 229.167 267.885 229.899 267.299 229.899C266.712 229.899 265.703 229.167 265.703 227.53C265.703 225.893 266.712 225.162 267.299 225.162C267.885 225.162 268.894 225.893 268.894 227.53Z" fill="#2B2B2B" stroke="black" stroke-width="3"/>
</g>
    );
}

export default VR;
