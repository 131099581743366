import React from 'react';
import {PFPContext} from './../../context';

export const EyeBrows = () => {
    const {eyeBrows} = React.useContext(PFPContext);

    if (eyeBrows === 'neutral') {
        return (
            <g id="neutral_2">
<path id="Vector 1_2" d="M1135.61 204.9H1187.12" stroke="black" stroke-width="12" stroke-linecap="round"/>
<path id="Vector 2_2" d="M1203.03 204.9H1254.55" stroke="black" stroke-width="12" stroke-linecap="round"/>
</g>
        );
    };

    return (
        <g id="right up brow">
<path id="Vector 1" d="M1135.61 204.9H1187.12" stroke="black" stroke-width="12" stroke-linecap="round"/>
<path id="Vector 2" d="M1201.52 210.96C1210.15 204.951 1215.52 202.88 1226.52 202.627C1240.43 203.265 1247.15 202.33 1257.58 198.839" stroke="black" stroke-width="12" stroke-linecap="round"/>
</g>
    );
}

export default EyeBrows;
